<template>
  <div>
    <l-tile-layer
        v-for="open in openaip"
        :key="open.name"
        :name="open.name"
        :visible="open.visible"
        :url="open.url"
        :attribution="open.attribution"
        :detectRetina="open.detectRetina"
        :tms="open.tms"
        :subdomains="open.subdomains"
        :minZoom="open.minZoom"
        :maxZoom="open.maxZoom"

        :opacity="open.opacity"
        :zIndex="open.zIndex"
    />
  </div>
</template>

<script>
import { LTileLayer } from 'vue2-leaflet';

export default {
  name: "TileAIP",
  components: {
    LTileLayer
  },
  data() {
    return {
      token: null,
      openaip: [
        {
          name: "OpenAIPmap",
          visible: true,
          url: `https://api.tiles.openaip.net/api/data/openaip/{z}/{x}/{y}.png?apiKey=2050edc19c6c61bee9affd022623e618`,
          attribution:
              '<a href="https://www.openaip.net/">openAIP Data</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-NC-SA</a>)',
          minZoom: 4,
          maxZoom: 20,
          //tms: true,
          detectRetina: true,
          subdomains: "12",
          opacity: 0.5,
          zIndex: 10,
          bounds: [[40.712, -74.227], [40.774, -74.125]],
          maxBounds: [[40.712, -74.227], [40.774, -74.125]]
        },
      ],
    }
  },
  methods: {
  },
  mounted() {
    //this.connectToOpenAIP()
  }
}
</script>