import axios from 'axios';
import store from "./store/store.js" 

export default class AtlasBackend {

    static init() {
        this.#setupAxios(process.env.VUE_APP_API_URL);
    }

    static async getDataFrom(query) {
        return (await axios.get(query, this.#getConfig())).data;
    }

    static async getDataSearch(query, data) {
        return (await axios.post(query, {searchStr: data}, this.#getConfig())).data;
    }

    static async postDataTo(query, data) {
        try {
            return (await axios.post(query, data,this.#getConfig())).data;
        } catch (error) {
            return -1
        }
    }

    static async putDataTo(query, data) {
        return (await axios.put(query, data, this.#getConfig())).data;
    }

    static async deleteDataAt(query) {
        return (await axios.delete(query, this.#getConfig())).data;
    }

    static getIdRole() {
        return store.getters.id_role;
    }

    static getQuerryForUser(query, userField) {
        
        if (store.getters.id_role !== 1)
            query += `${userField}/` + store.getters.id_role;
        return query;
    }

    static #getConfig(data) {
        let config = {headers: {Authorization: `Bearer ${store.state.token}`}};
        
        if (data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        }

        return config;
    }

    static #setupAxios(baseURL) {
        //url de base vers l'API, on l'utilise
        //comme paramètre par défaut pour chaque requête axios
        axios.defaults.baseURL = baseURL;
        //interception des erreurs 401 ou 403 après une requête axios
        //cela signifie que le token n'est plus valide, on redirige l'utilisateur
        //vers la page de login
        axios.interceptors.response.use((response) => {
            //cas sans erreur on renvoie la réponse
            return response;

            //cas dans lequel on détecte une erreur d'authentification
        }, (error) => {

            let codeError;
            // cas erreur mais pas d'infos supplémentaires
            try {
                // get du status de l'erreur
                codeError = error.response.status;
            } catch (err) {
                console.log("Erreur autre auth:", error)
                return Promise.reject(error);
            }
            
            if (codeError === 401 || codeError === 403) {
                //on déconnecte alors l'utilisateur
                this.$store.commit('logoutUser');
        
                if (this.$route.name !== 'Home') {
                    //redirection de l'utilisateur vers la page d'accueil
                    this.$router.push('/', () => {
                        //notification de déconnexion
                        this.$notify({
                            type: 'warn',
                            text: 'Votre session a expirée!',
                        });
                    });
                }
            }
            //on retourne l'erreur tel quel
            return Promise.reject(error);
        });
    }
}