<!-- Page de gestion des antennes Atlas -->
<template>
  <div>
    <h3 class="mt-5 mb-5"> Gestion des antennes Atlas </h3>
    
    <!-- loop chargement des données -->
    <div v-if="!stationsData">
      <b-spinner class="mt-5"></b-spinner>
    </div>
    <!-- cas données -->
    <div v-else-if="stationsData && stationsData.length > 0">
      <b-container>
        <b-row>
          <!-- Pour chaque antenne, on crée une carte -->
          <b-col v-for="station in stationsData" :key="station.name" cols="3">

            <!-- Carte d'antenne avec couleur dynamique selon son état -->
            <b-card header-tag="header" class="m-1" footer-tag="footer" style="max-width: 23rem;"
              :header-bg-variant="station.isActive ? 'success' : 'warning'"
              :header-text-variant="station.isActive ? 'light' : 'black'">
              
              <!-- Header avec nom de l'antenne-->
              <template #header>
                <h6 class="mb-0">
                  <!-- icône antenne active -->
                  <b-icon v-if="station.isActive" icon="circle-fill" animation="throb" font-scale="1"/>
                  <!-- icône antenne inactive -->
                  <b-icon v-else icon="wifi-off" font-scale="1"/>
                  {{station.Name}}
                </h6>
              </template>

              <!-- Essentiel: état des services + dernière détection -->
              <b-card-text>
                <b-row>
                  <b-col :style="{'font-size': '15px'}">
                    Dernier ping: {{ getAntennaTime(station.LastDetection.Seconds) }}
                  </b-col>
                </b-row>
                <hr v-if="station.isActive">
                <!-- affichage des services et des boutons de manip -->
                <div v-if="station.isActive">
                  <b-row class="mb-1" v-for="service in services" :key="service.name">
                    <b-col cols="3">{{service.name}}</b-col>

                    <!-- coloration dynamique selon statut service -->
                    <b-col cols="4" 
                      :style="{'color': station[service.name] == 'active' ? 'green' : 'grey'}">
                      {{station[service.name] || 'inactive'}}
                    </b-col>
                    <b-col>
                      <!-- Bouton de reload -->
                      <b-button variant="outline-light" size="sm" class="mb-2"
                        @click="reloadService(station.Name, service.software)">
                        <b-icon icon="arrow-repeat" variant="warning" font-scale="1.5"/>
                      </b-button>
                      <!-- Bouton stop -->
                      <b-button variant="outline-light" size="sm" class="mb-2"
                        @click="stopService(station.Name, service.software)">
                        <b-icon icon="stop-btn" variant="danger" font-scale="1.5"/>
                      </b-button>
                    </b-col>
                  </b-row> 
                </div>               
              </b-card-text>
                <!-- Footer avec des détails sur l'antenne -->
              <template #footer>
                <em> Détails: {{ station.Description }}</em>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- pas de données  -->
    <div v-else-if="stationsData.length == 0">
      Aucune antenne enregistrée
    </div>
  </div>
</template>

<script>
import Station from '../../Station.mjs';
import * as fct from '../componentFunctions';
import _ from 'lodash';

export default {
  name: "Vol",
  components: {},

  data() {
    return {
      stationsData: null,
      // tableau des noms + libellés des services
      services: [
        {'name':'Adsb',  'software':'adsb'},
        {'name':'Llrtm', 'software':'rtlsdr-ogn'},
        {'name':'Wifi', 'software':'beacons'}
      ]
    }
  },
  methods: {
    getAntennaTime: fct.getTimestampTime,

    // requête les données de toutes les antennes enregistrées
    async getAntennasData() {
      this.stationsData = await Station.getAll() || [];
    },

    // fonction de requête reload pour les différents services + debounce pour éviter le spam
    reloadService: _.debounce(async function (stationName, service) {
      await Station.reloadService(stationName, service)
      .then(() =>{        
          //notification de commande exécutée
          this.$notify({
            type: 'success',
            text: 'Commande exécutée!',
          });
        }).catch(()=>{
          // notif de succès
          this.$notify({
            type: 'warning',
            text: 'La commande a échouée!',
          });
        })
      }, 
    20000),

    // fonction de requête stop pour les différents services + debounce pour éviter le spam
    stopService: _.debounce(async function (stationName, service) {
      await Station.stopService(stationName, service)
      .then(() =>{        
          //notification de commande exécutée
          this.$notify({
            type: 'success',
            text: 'Commande exécutée!',
          });
        }).catch(()=>{
          // notif de succès
          this.$notify({
            type: 'warning',
            text: 'La commande a échouée!',
          });
        })
      }, 
    20000),

  },

  mounted() {
    this.getAntennasData();
  }
}
</script>
