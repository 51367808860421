import Balise from "../Balise.mjs"

export default class LLRTM extends Balise {
    static async getLLRTMBalise(baliseId) {
        return new LLRTM(await this._getBalise("llrtm", baliseId));
    }

    constructor(baliseData) {
        super(baliseData);
    }

    get Tech() {
        return "LLRTM";
    }

    async save() {
        await this._save("/llrtm/", {});
    }
}