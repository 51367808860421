<template>
  <div id="app">
    <!-- composant de notification -->
    <notifications :width="250" position="top center"/>
    <!-- pour chaque page en dehors du login et de la map, on affiche notre navbar (disparaitra ptete)-->
    <Navbar v-if="$route.path !== '/map' && $route.path !== '/'"/>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>

import AtlasBackend from "./AtlasBackend.mjs";
import Navbar from "./components/Navbar";

export default {
  components: {Navbar},
  methods: {
    resizeWidth() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth > 1200) {
        this.$store.commit('setScreenSize', {
          height: window.innerHeight,
          width: window.innerWidth,
          widthBreakpoint: "xl",
          sidebarFontsize: '19px',
          sidebarHeight: 'auto',
          titleFontsize: '18px'
        })
      } else if (this.windowWidth > 922) {
        this.$store.commit('setScreenSize', {
          height: window.innerHeight,
          width: window.innerWidth,
          widthBreakpoint: "lg",
          sidebarFontsize: '17px',
          sidebarHeight: 'auto',
          titleFontsize: '17px'
        })
      } else if (this.windowWidth > 768) {
        this.$store.commit('setScreenSize', {
          height: window.innerHeight,
          width: window.innerWidth,
          widthBreakpoint: "m",
          sidebarFontsize: '15px',
          sidebarHeight: 'auto',
          titleFontsize: '16px'
        })
      } else if (this.windowWidth > 576) {
        this.$store.commit('setScreenSize', {
          height: window.innerHeight,
          width: window.innerWidth,
          widthBreakpoint: "sm",
          sidebarFontsize: '14px',
          sidebarHeight: '500px',
          titleFontsize: '15px'
        })
      } else {
        this.$store.commit('setScreenSize', {
          height: window.innerHeight,
          width: window.innerWidth,
          widthBreakpoint: "xs",
          sidebarFontsize: '14px',
          sidebarHeight: '350px',
          titleFontsize: '15px',
        })
      }
    }
  },
  mounted() {
    // Permet de connaître la taille de l'écran pour le rendre responsive
    this.windowWidth = window.innerWidth
    this.$nextTick(() => {
      window.addEventListener('resize', this.resizeWidth);
    })
    this.resizeWidth()

    AtlasBackend.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeWidth);
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap');
@import './assets/main.css';
</style>