<template>
  <div>
    <div class="container">
      <h1 class="mt-4">Itinéraires</h1>
      Ajouter un itinéraire:
      <b-form @submit="createItineraire" class="text-start">
        <label class="mt-3">Coordonnées</label>
        <b-form-input v-model="coordonnees" class="mt-1" placeholder="[[lat, lon], [lat, lon]]"></b-form-input>
        <br>
        <label class="mt-3">Nom</label>
        <b-form-input v-model="nom" class="mt-1" placeholder="Nom *"></b-form-input>
        <br>
        <label class="mt-3">Mission</label>
        <b-form-input v-model="id_mission" class="mt-1" placeholder="Mission"></b-form-input>
        <div class="text-center">
          <b-button type="submit" size="lg" variant="success" class="mt-3 shadow-lg">Enregistrer</b-button>
        </div>
      </b-form>
      <hr>
    </div>
    <div>
      Liste des itinéraires :
      <b-table striped hover :fields="fields" :items="itinerairesData">
        <template #cell(modifier)>
          <b-button variant="primary">Modifier</b-button>
        </template>
        <template #cell(supprimer)="data">
          <b-button @click="deleteItineraire(data.item.Id_itineraire)" variant="danger">Supprimer</b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Itineraire",
  data() {
    return {
      coordonnees: null,
      nom: null,
      id_mission: null,
      itinerairesData: null,
      fields: [
        "Id_itineraire",
        "Coordonnees",
        "Nom",
        "Id_mission",
        "Modifier",
        "Supprimer",
      ],
      itineraireDeleted: false
    }
  },
  methods: {
    // Récupère les itinéraires
    async getItineraires() {
      const config = {headers: {Authorization: `Bearer ${this.$store.state.token}`}};
      this.itinerairesData = (await axios.get('/itineraire', config)).data;
    },
    // Créé un itinéraire
    async createItineraire() {
      event.preventDefault();
      const config = {headers: {Authorization: `Bearer ${this.$store.state.token}`}};
      const data = { Coordonnees: this.coordonnees, Nom: this.nom, Id_mission: this.id_mission };
      if (await axios.post('/itineraire', data, config)) {
        this.coordonnees = null
        this.nom = null
        this.id_mission = null
      }
      await this.getItineraires()
    },
    // Supprime un itinéraire
    async deleteItineraire(id) {
      const config = {headers: {Authorization: `Bearer ${this.$store.state.token}`}};
      if (await axios.delete(`/itineraire/${id}`, config)) {
        this.itineraireDeleted = true;
      }
      await this.getItineraires();
    },
  },
  mounted() {
    this.getItineraires()
  }
}
</script>

<style scoped>
.container {
  max-width: 500px;
}
</style>