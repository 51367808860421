import Vue from 'vue'
import Notifications from 'vue-notification'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import VueSimpleAlert from "vue-simple-alert";

Vue.config.productionTip = false

// ----- Bootstrap & Bootstrap-vue -----
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// ----- Vue Leaflet --------------------
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

// -------- Animate.css -----------------
import 'animate.css';

// --------- Librairies diverses --------
Vue.use(Notifications);
Vue.use(VueSimpleAlert);

//Déclaration de l'application
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
