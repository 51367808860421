import AtlasBackend from "./AtlasBackend.mjs";
import Coordinates from "./Coordinates.mjs";

export default class Cage {
    static async getCages() {
        let cages = [];

        const querry = AtlasBackend.getQuerryForUser("/cage/", "groupe");
        for (const cageData of await AtlasBackend.getDataFrom(querry)) {
            let newCage = new Cage(cageData);

            cages.push(newCage);
        }
        
        return cages;
    }

    static async getCage(Id) {
        return (await this.getCages()).find(cage => cage.Id_cage == Id);
    }

    #Id;
    #Data;
    #CageFile;
    
    constructor(cageData) {
        this.#Id = cageData.id;
        this.Nom = cageData.name;
        this.Id_drone = cageData.droneId;
        this.#Data = new Coordinates(cageData.coordinates);
        this.#CageFile = cageData.cageFile;
    }

    get Id_cage() {
        return this.#Id;
    }

    async save() {
        const querry = "/cage/";
        if (this.#Id) {
            const saveData = {
                Nom: this.Nom,
                Id_drone: this.Id_drone,
            };

            await AtlasBackend.putDataTo(querry + `${this.#Id}`, saveData);
        } else {
            let saveData = new FormData();
            saveData.append('file', this.#CageFile);
            saveData.append('nom', this.Nom);
            saveData.append('id_drone', this.Id_drone);

            await AtlasBackend.postDataTo(querry, saveData);
        }
    }

    async remove() {
        await AtlasBackend.deleteDataAt(`/cage/${this.#Id}`);
    }

    get Coordinates() {
        return this.#Data.Coordinates;
    }
}