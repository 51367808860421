
export default class Timestamp {
    #Data;

    constructor(TimestampData) {
        this.#Data = TimestampData;
    }

    get time() {
        let hours = Math.floor(this.#Data / 60 / 60) ;
        let minutes = Math.floor(this.#Data / 60) - (hours * 60)
        let seconds = this.#Data % 60;
        let res = ''
        if(hours > 0) res += hours + 'h '
        if(minutes > 0) res += minutes + 'm '
        if(seconds > 0) res += seconds + 's '
        return res
    }
    
    // Formate l'heure et la date de détection à partir d'un timestamp
    get date() {
        //conversion en nombre
        let timestamp = parseInt(this.#Data)
        // si l'on utilise un timestamp unix, il faut le * par 1000 pour le convertir
        if(timestamp.toString().length < 11) 
            timestamp = timestamp*1000

        // si la valeur fournie n'est pas un entier renvoie un null
        if (!Number.isInteger(timestamp)) return null
        
        return new Date(timestamp).toLocaleDateString('fr-FR') 
            +" "+ new Date(timestamp).getHours()
            +':'+('0' + new Date(timestamp).getMinutes().toString()).slice(-2)
            +':'+('0' + new Date(timestamp).getSeconds().toString()).slice(-2)
    }

    get minutesSince() {
        let actualDate = new Date(+new Date())
        let droneDate = new Date(parseInt(this.#Data))
        let diff = actualDate.getTime() - droneDate.getTime();
        return (diff / 60000);
    }

    get Seconds() {
        return this.#Data;
    }
}