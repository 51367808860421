<template>
  <div>
    <div class="container">
      <h1 class="mt-4">Mission : {{missionDetails.Nom_mission}}</h1>
      <hr>
      <div class="row">
        <div class="col">
          <h3>Informations de la mission</h3>
          <br>
          <div class="text-start">
            <h4>Nom de mission : {{missionDetails.Nom_mission}} </h4>
            <hr>
            <h4>Date de création : {{missionDetails.Mission_date_creation}}</h4>
            <hr>
            <h4>Durée de mission : </h4>
            <hr>
            <h4>Terminal de départ : </h4>
            <hr>
            <h4>Terminal d'arrivée : </h4>
          </div>
        </div>
        <div class="col">
          <h3>Element(s) lié(s) à la mission</h3>
          <br>
          <div class="text-start">
            <h4>Drone(s) : </h4><br>
            <div>
              ID du drone : {{drones[0].Drone_id}}<br>
              Longitude : {{drones[0].Longitude}}<br>
              Latitude : {{drones[0].Latitude}}<br>
              Altitude : {{drones[0].Altitude}}<br>
              Vitesse : <br>
              Date de dernière détection : {{drones[0].Timestamp}}<br>
            </div>
            <hr>
            <h4>Vol(s) : </h4><br>
            Aucune données de vol pour cette mission.
            <hr>
            <h4>Cage(s) : </h4><br>
            Cage utilisée : {{cages[0].Nom}}
            <hr>
            <h4>Itinéraire(s) : </h4><br>
            Aucune données d'itinéraire pour cette mission.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "MissionDetails",
  data() {
    return {
      missionDetails: null,
      drones: null,
      vols: null,
      cages: null,
      itineraires: null,
    }
  },
  methods: {
    // Obtiens les détails de la mission sélectionnée
    async getMissionDetails() {
      const config = {headers: {Authorization: `Bearer ${this.$store.state.token}`}};
      
      let response = await axios.get('/mission/' + this.$route.params.id, config);
      this.missionDetails = response.data[0]
      console.log(response)
      
      response = await axios.get('/air-drone/id-mission/' + this.$route.params.id, config);
      this.drones = response.data
      console.log(response)

      this.cages = (await axios.get('/cage/id-mission/' + this.$route.params.id, config)).data;
    }
  },
  mounted() {
    this.getMissionDetails()
  }
}
</script>

<style scoped>

</style>