import AtlasBackend from "./AtlasBackend.mjs";
import Position from "./Position.mjs";
import Timestamp from "./Timestamp.mjs";

// classe Antenne
export default class Station {
    static async getAll() {
        let stations = [];

        const query = "/antenne/";
        // pour chaque antenne enregistrée en BDD on crée un objet antenne js
        for (const stationData of await AtlasBackend.getDataFrom(query)) {
            let newStation = new Station(stationData);
            stations.push(newStation);
        }
        // trie le tableau de stations par ordre croissant du nom/id
        return stations.slice().sort((a, b) => {    
            return a.Name.localeCompare(b.Name);
        });
    }

    // Attributs
    #Id;
    #Name;
    #Pos;
    #Description;
    #Adsb_statut;
    #Llrtm_statut;
    #Wifi_statut;
    #LastDetection;

    constructor(stationData) {
        // association attributs de la classe aux champs BDD
        this.#Id   = stationData.id;
        this.#Name = stationData.name;
        // une position =  alt,lon,alt
        this.#Pos  = new Position({
            Longitude: stationData.longitude,
            Latitude: stationData.latitude,
            Altitude: stationData.altitude
        });
        this.#Description      = stationData.description;
        this.#Adsb_statut      = stationData.adsb_service;
        this.#Llrtm_statut     = stationData.llrtm_service;
        this.#Wifi_statut      = stationData.beacon_service;
        this.#LastDetection    = new Timestamp(stationData.timestamp);
    }

    get Id() {return this.#Id;}
    get Name() {return this.#Name;}
    get Position() {return this.#Pos;}
    get Wifi() { return this.#Wifi_statut}
    get Adsb() { return this.#Adsb_statut}
    get Llrtm() { return this.#Llrtm_statut}
    get Description() { return this.#Description}
    get LastDetection() {return this.#LastDetection;}

    // définis le statut de l'antenne selon sa dernière détection
    get isActive() {
        let minutesDiff = ((new Date().getTime()/1000) - this.#LastDetection.Seconds)/60
        // active si ping il y a 5 minutes ou moins
        if (minutesDiff >= 0 && minutesDiff <= 5) 
            return true;
        return false;
    }

    // requête de redémarrage d'un service
    static async reloadService(stationName,service){
        service = service.toLowerCase().trim();
        let command = 'sudo service '+ service +' restart'
        
        const saveData = {
            id: stationName,
            cmd: command
        }
        return await AtlasBackend.postDataTo('/antenne/cmd', saveData);
    }
    // requête d'arrêt d'un service
    static async stopService(stationName,service){
        service = service.toLowerCase().trim();
        let command = 'sudo service '+ service +' stop'
        const saveData = {
            id: stationName,
            cmd: command
        }
        return await AtlasBackend.postDataTo('/antenne/cmd', saveData);
        
    }
}