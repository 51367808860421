<template>
  <div class="boxChart" style=" box-shadow: 0 0 20px black;">
    <!-- Titre du graphique -->
    <div class="float-start">
      <h5 class="py-2 ms-2" style="color: white; ">Données de mission : {{missionName}}</h5>
    </div>
    <!-- Graphique -->
    <div class="chart">
      <canvas id="droneChart"></canvas>
    </div>
  </div>
</template>

<script>
// Import de la librairie Chart.js
import Chart from 'chart.js/auto';

export default {
  name: 'DroneAltiChart',
  // Récupération des données depuis le composant parent
  props: {
    altitudeData: Object,
    missionData: Array,
    missionName: String
  },
  data() {
    return {
      // Données du graphique
      chartData: {
        labels: [],
        datasets: [{
          label: 'Profil Altimétrique',
          data: null,
          borderWidth: 5,
        }]
      },
      show: null,
      distance: [],
      percentage: [],
      wpAlt: [300,  340, 1000],
      wpAltData: [{x: 700, y: 50}],
      wpArray: null
    }
  },
  methods: {
    // Génère un array correspondant à la courbe des waypoints
    chartWayPoint(wayPoints, samples) {
      let percentage = this.percentage
      console.log(samples)
      let result = []
      result.push({x: 0, y: wayPoints[0]})
      for (let i = 1; i <= wayPoints.length - 2; i++) {
        console.log(percentage[i])
        result.push({x: Math.ceil(percentage[i - 1]), y: wayPoints[i]})
        // console.log({x: i * spacing, y: wayPoints[i]})
      }
      result.push({x: samples - 1, y: wayPoints[wayPoints.length - 1]})
      for (let a = 0; a < result.length - 1; a++) {
        console.log(JSON.stringify(result[a]))
      }
      return result;
    },
    // Génère un array correspondant à la courbe des waypoints stepped
    chartWayPointStepped(wayPoints) {
      let pos = []
      for (let i = 0; i < wayPoints.length; i++) {
        pos.push(wayPoints[i])
        if (i !== wayPoints.length - 1) {
          if (wayPoints[i].y < wayPoints[i + 1].y) {
            pos.push({x: wayPoints[i].x, y: wayPoints[i + 1].y, checkpoint: true})
          } else if (wayPoints[i].y > wayPoints[i + 1].y) {
            pos.push({x: wayPoints[i + 1].x, y: wayPoints[i].y, checkpoint: true})
          }
        }
      }
      console.log(pos)
      return pos
    },
    // Conversion des degrès en radian
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    },
    // Permet d'obtenir la distance entre deux coordonnées (en km)
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      let R = 6371; // Rayon de la Terre
      let dLat = this.deg2rad(lat2 - lat1);
      let dLon = this.deg2rad(lon2 - lon1);
      let a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = R * c; // Distance en km
      return d;
    },
    // Permet de récupérer la distance entre deux waypoints
    getRouteDistance(waypoints) {
      let sum = 0;
      this.distance.push(sum)

      for (let cnt = 0; cnt < waypoints.length - 1; cnt++) {
        let result = this.getDistanceFromLatLonInKm(waypoints[cnt][0], waypoints[cnt][1], waypoints[cnt + 1][0], waypoints[cnt + 1][1])
        this.distance.push(result)
        this.distance[0] += result
      }
      for (let i = 1; i < this.distance.length; i++) {
        sum += this.distance[i]
        this.percentage.push(((sum / this.distance[0]) * 100).toFixed(2))
      }
      console.log(this.distance)
    }
  },
  watch: {
    altitudeData() {
      this.show = true;
      let arraytmp = []
      let labeltmp = []
      let limit = []
      this.getRouteDistance(this.missionData)
      for (const items of this.$props.altitudeData.results) {
        arraytmp.push(items.elevation);
        limit.push(items.elevation + 120)
        labeltmp.push(this.$props.altitudeData.results.indexOf(items))
      }
      let wayPointsArray = this.chartWayPoint(this.wpAlt, labeltmp.length)
      this.wpArray = this.chartWayPointStepped(wayPointsArray)
      let ctx = document.getElementById('droneChart').getContext("2d");
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: labeltmp,
          datasets: [
            {
              radius: 6,
              label: 'Altitude WayPoints (Stepper)',
              backgroundColor: 'rgba(255, 0, 0, 0)',
              borderColor: 'rgba(255, 0, 0, 0.9)',
              // color: 'rgba(255, 255, 255, 0.8)',
              borderWidth: 3,
              data: this.wpArray,
              spanGaps: true,
              hidden: true
            },
            {
              radius: 6,
              label: 'Altitude WayPoints ',
              backgroundColor: 'rgba(255, 255, 0, 0.4)',
              borderColor: 'rgba(255, 255, 0, 0.9)',
              // color: 'rgba(255, 255, 255, 0.8)',
              borderWidth: 3,
              data: wayPointsArray,
              spanGaps: true,
              hidden: false
            },
            {
              radius: 0,
              label: 'Profil Altimétrique ',
              backgroundColor: 'rgba(0, 255, 0, 1)',
              borderColor: 'rgba(0, 255, 0, 0.9)',
              // color: 'rgba(255, 255, 255, 0.8)',
              borderWidth: 3,
              data: arraytmp,
            },
            {
              radius: 0,
              label: 'Limite hauteur cage ',
              backgroundColor: 'rgba(0, 0, 255, 0)',
              borderColor: 'rgba(0, 0, 255, 0.9)',
              // color: 'rgba(255, 255, 255, 0.8)',
              borderWidth: 3,
              data: limit,
              hidden: true
            }
          ]
        },
        options: {
          responsive: true,
          elements: {
            point: {
              pointStyle: function (ctx) {
                //console.log(ctx)
                const index = ctx.raw.checkpoint;
                return index === true ? 'rect' : 'circle';
              }
            },
          },
          scales: {
            x: {
              display: false
            }
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 15,
                color: 'rgb(255, 255, 255)',
                font: {
                  size: 16,
                  family: 'Readex Pro',
                },
              },
            },
          }
        }
      });
      ctx.update()
    },
    missionData() {
      this.getRouteDistance(this.missionData)
    }
  }
}
</script>

<style scoped>

#droneChart {
  max-height: 35vh;
}

.boxChart {
  max-width: 75%;
}

.chart {
  background-color: rgba(1, 1, 1, 0.9);
  width: 100%;
  color: white;
}
</style>