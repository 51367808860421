import Vue from 'vue'
import VueRouter from 'vue-router'

// Vues utilisées dans les routes
import Map from "../views/Map";
import Login from "../views/Login";
import Page404 from "../views/Page404";
import MissionDetails from "../components/mission/MissionDetails";
import Cages from "../components/mission/Cages";
import Drone from "../components/mission/Drone";
import Vol from "../components/mission/Vol";
import Antenne from "../components/mission/Antenne";
import Itineraire from "../components/mission/Itineraire";
import Mission from "../components/mission/Mission";
import AtlasBackend from "../AtlasBackend.mjs";
import store from "../store/store.js"

Vue.use(VueRouter)

// Définition des compsants/pages à charger selon l'url fourni
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      //page de connexion sur l'url de base mais si l'utilisateur est dédjà connecté il passe la page map
      path: '/',
      name: 'Home',
      component: Login,
    },
    {
      //page principale une fois connecté
      path: '/map',
      name: 'Map',
      component: Map,
      //check de l'authentification de l'utilisateur avant d'y accéder
      beforeEnter(to,from,next){ isUserLogged(to,from,next)}
    },
    // Page missions
    {
      path: '/missions',
      name: 'Mission',
      component: Mission,
      beforeEnter(to,from,next){ isUserLogged(to,from,next)}
    },
    // Détails d'une mission, je ne sais pas ramy écrits tes comments
    {
      path: '/mission/:id',
      name: 'MissionDetails',
      component: MissionDetails,
      beforeEnter(to,from,next){ isUserLogged(to,from,next)}
    },
    // Page des cages
    {
      path: '/cages',
      name: 'Cages',
      component: Cages,
      beforeEnter(to,from,next){ isUserLogged(to,from,next)}
    },
    // Liste des drones
    {
      path: '/drones',
      name: 'Drone',
      component: Drone,
      beforeEnter(to,from,next){ isUserLogged(to,from,next)}
    },
    // Page de la liste des vols enregistrés
    {
      path: '/vols',
      name: 'Vols',
      component: Vol,
      beforeEnter(to,from,next){ isUserLogged(to,from,next)}
    },
    // Page de la liste des antennes actives
    {
      path: '/antenne',
      name: 'Antenne',
      component: Antenne,
      async beforeEnter(to,from,next){ 
        try {
          //si le token est valide, 
          await AtlasBackend.getDataFrom(process.env.VUE_APP_API_URL + '/user/token'); 
          // s'il a les droits, on affiche la page des antennes
          if(store.getters.id_user == 28)
            next()
          //si l'utilisateur n'a pas le droit, on le redirige vers la page vols
          else 
            next('/vols');
        // cas token invalide => page login
        } catch {
          next('/');
        }
      }
    },
    // Liste des itinéraires
    {
      path: '/itineraires',
      name: 'Itineraire',
      component: Itineraire,
      beforeEnter(to,from,next){ isUserLogged(to,from,next)}
    },
    {
      //si l'url fournis n'est pas connu alors page 404
      path: "*", 
      component: Page404,
      beforeEnter(to,from,next){isUserLogged(to,from,next)}
    }
  ],

});

// function de vérification de la validité du token
async function isUserLogged (to,from,next) {
  //on utilise la route de validation de token, 200 si token ok ou 400 si pasok
  try {
    //si le token est valide, on continue la navigation
    await AtlasBackend.getDataFrom(process.env.VUE_APP_API_URL + '/user/token'); next();

  } catch {
    //sinon on le redirige vers la page de login
    next('/');
  }
}
export default router;
