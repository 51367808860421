<!-- Template de visualisation et d'affilations de drones aux balises reconnues -->
<template>
  <b-overlay>
    <div class="container">
      <!-- formulaire d'enregistrement d'un nouveau drone -->
      <h1 class="mt-4">Drones</h1>
      Ajouter un drone :
      <b-form @submit="createDrone" class="text-start">

        <!-- Champs nom du drone avec erreurs -->
        <label class="mt-3">Nom du Drone</label><br/>
        <b class="policeRed" v-if="!droneNameValid(droneName)">Un drone avec ce nom existe déjà</b>
        <b-form-input v-model="droneName" class="mt-1" placeholder="Ex : Matrice300" required></b-form-input>

        <!-- Champs nom de l'id alpha tango avec erreurs -->
        <label class="mt-3">Identifiant Alpha Tango</label><br/>
        <b class="policeRed"  v-if="!alphaTangoIdValid(AlTangoID)">Id déjà associé à un autre drone</b>
        <b-form-input v-model="AlTangoID" class="mt-1" placeholder="Ex : OWO1200"></b-form-input>

        <!-- bouton d'enregistrement qui s'active que si les champs sont corrects -->
        <div class="text-center">
          <b-button :disabled="!validateDroneInput(droneName, AlTangoID)" type="submit" size="lg" variant="success" class="mt-3 shadow-lg">Enregistrer</b-button>
        </div>
      </b-form>
      <hr>
    </div>

    <!-- liste des drone enregistré et associés à cet utilisateur -->
    <h3 class="mb-5 mt-1">Liste des drones :</h3>
    <p v-if="!dronesData">
      Aucun drone n'a été enregistré.
    </p>
    <!-- remplissage du tableau avec les données de drones -->
    <b-table responsive v-else striped hover :fields="col_fields" :items="dronesData"
      label-sort-asc="" label-sort-desc="" label-sort-clear="" v-model="dronesData">
      <!-- Bouton Affiliation -->
      <template #cell(affilier)="data">
        <b-button variant="primary" @click="triggerModalAffiliation(data.item)">Référencer</b-button>
      </template>
        <!-- Bouton Modification -->
        <template #cell(modifier)="data">
        <b-button variant="warning" @click="triggerModalUpdate(data.item.Id_drone)">Modifier</b-button>
      </template>
      <!-- Bouton Supprimer -->
      <template #cell(supprimer)="data">
        <b-button @click="deleteDrone(data.item)" variant="danger">Supprimer</b-button>
      </template>
    </b-table>

    <!-- Modal d'affiliation de balises à un drone -->
    <b-modal v-model="showModalAffiliation" @close="showModalAffiliation = false" 
            ref="my-modal" hide-footer title="Afilliation de balises">
      <div class="d-block text-center">
        <div>
          <!-- combox de sélection du type de balise à affilier -->
          <label class="typo__label">Choisissez une technologie</label>
          <multiselect v-model="value" :options="options" placeholder="..."
            selectLabel="" deselectLabel="" selectedLabel="sélectionné"></multiselect>

               <!-- selon la techno choisie on change le modal -->
          <div class="text-start">
            <div v-if="value =='Wi-fi'">
              <!-- Affiliation balise wifi -->
              <label class="mt-3">ID de la balise Wi-fi</label>
              <b-form-input v-model="baliseId" class="mt-1" placeholder="Ex : OWO1200" required></b-form-input>
            </div>
            <!-- Affiliation balise llrtm -->
            <div v-if="value =='LLRTM'">
              <label class="mt-3">ID de la balise LLRTM</label>
              <b-form-input v-model="baliseId" class="mt-1" placeholder="Ex : OWO1200" required>
              </b-form-input>
            </div>

            <!-- Affiliation satellite -->
            <div v-if="value =='Satellite'">
              <label class="mt-3">ID de la balise satellite</label>
              <b-form-input v-model="baliseId" class="mt-1" placeholder="Ex : OWO1200" required>
              </b-form-input>
            </div>
            <!-- bouton de validation d'affiliation -->
            <div class="text-center">
              <b-button type="submit" size="lg" variant="success" class="mt-3 shadow-lg" 
              @click="affiliateDrone(value)" :disabled="baliseId === ''">Affilier</b-button>
            </div>
          </div>
       </div>
      </div>
    </b-modal>

    <!-- Modal de modification d'un drone -->
    <b-modal v-model="showModalUpdate" @close="showModalUpdate = false;"  hide-footer title="Modification des données d'un drone">
        <!-- nouveau nom -->
        <label class="mt-3">Nom du drone</label>
        <b class="policeRed" v-if="!droneNameValid(newDroneName)">Un drone avec ce nom existe déjà</b>
        <b-form-input v-model="newDroneName" class="mt-1" placeholder="Ex : Matrice300" required></b-form-input>

        <!-- nouvel identifiant -->
        <label class="mt-3">Identifiant Alpha Tango</label>
        <b class="policeRed"  v-if="!alphaTangoIdValid(newAlTangoID)">Id déjà associé à un autre drone</b>
        <b-form-input v-model="newAlTangoID" class="mt-1" placeholder="Ex : OWO1200" ></b-form-input>

        <!-- btn d'enregistrement dynamique -->
        <div class="text-center">
          <b-button :disabled="!validateDroneInput(newDroneName, newAlTangoID)" size="lg" variant="success" @click="updateDrone()" class="mt-3 shadow-lg">Enregistrer</b-button>
        </div>
    </b-modal>
  </b-overlay>
  
</template>

<script>
import Drone from "../../Drone.mjs";
import Beacon from '../../balises/Beacon.mjs';
import LLRTM from '../../balises/LLRTM.mjs';
import Satellite from '../../balises/Satellite.mjs';

//paquet de la combobox
import Multiselect from 'vue-multiselect'

export default {
  name: "Drone",
  components: {
    Multiselect
  },

  data() {
    return {
      //variables des inputs
      droneName:null, 
      AlTangoID: null,
      newDroneName: null,
      newAlTangoID: null,

      // variables des modals
      showModalAffiliation: false,
      showModalUpdate: false,
      //données passées au modal d'affiliation, tel que le drone sur lequel faire des modifs
      modalAffiliationData: null,
      // données pour le modal de maj d'un drone(que l'id du drone pour le moment)
      modalUpdateData: null,
      // tableau des données des drones  (getall)
      dronesData: [],

      // id de la balise à affilier
      baliseId: '',
      
      // noms des colonnes de la liste des drones
      col_fields: [
        {key: "Nom_drone",  sortable: true,  label: "Nom du drone"},
        {key: "Id_mission",  sortable: true,  label: "Id_mission"},
        {key: "Id_balise",  sortable: true,  label: "Balise Wi-fi"},
        {key: "Id_tracker", sortable: true,  label: "Balise LLRTM"},
        {key: "Id_sat",     sortable: true,  label: "Balise satellite"},
        {key: "Id_altango", sortable: true,  label: "ID Alpha Tango "},
        {key: "Affilier",   sortable: false, label: ""},
        {key: "Modifier",   sortable: false, label: ""},
        {key: "Supprimer",  sortable: false, label: ""}
      ],

      // valeur de la liste des techno d'affiliation
      value: null,
      // techno disponible dans la liste déroulante d'affiliation
      options: ['Wi-fi','LLRTM', 'Satellite'],
      }
  },
  methods: {
    droneNameValid(droneName) {
      return !(droneName && this.dronesData.find(drone => drone.Nom_drone && drone.Nom_drone == droneName));
    },

    alphaTangoIdValid(AlTangoID) {
      return !(AlTangoID && this.dronesData.find(drone => drone.Id_altango && drone.Id_altango == AlTangoID));
    },

    validateDroneInput(droneName, AlTangoID) {
      return (droneName || AlTangoID) && this.droneNameValid(droneName) && this.alphaTangoIdValid(AlTangoID);
    },

    // fonction activant le modal d'affiliation de balises à un drone
    async triggerModalAffiliation(drone) {
      this.modalAffiliationData = drone
      this.showModalAffiliation = true
    },

    // fonction activant le modal de modif d'un drone
    async triggerModalUpdate(id_drone) {
      this.modalUpdateData = id_drone
      this.showModalUpdate = true
    },

    // Fonction d'enregistrement d'un drone
    async createDrone() {
      event.preventDefault();

      // création de l'objet drone (nom + id de l'utilisateur + id alpha tango)
      let newDrone = new Drone({
        name: this.droneName,
        altangoId: this.AlTangoID,
        groupeId: this.$store.getters.id_role
      });

      // requêtes
      await newDrone.save();
      this.droneId = null;
      this.$notify({type: 'success', text: 'Drone créé !'});

      // on requête les drones enregistrés ce qui force le rendu et rafraîchis la liste
      this.dronesData = await Drone.getDrones();

      // on reset les valeurs des champs du formulaire
      this.droneName = ""; this.AlTangoID = "";
    },

    // méthode de maj d'un ou plusieurs champs de la table air drone
    async updateDrone() {
      let updatedDrone = await Drone.getDrone(this.modalUpdateData);
      if (this.newDroneName) updatedDrone.Nom_drone = this.newDroneName;
      if (this.newAlTangoID) updatedDrone.Id_altango = this.newAlTangoID;

      try {
        await updatedDrone.save();
        this.$notify({type: 'success',text: 'Données modifiées !'});

        // une fois la requête faite sans erreurs, on rafraîchit le tout 
        this.dronesData = await Drone.getDrones();
        this.showModalUpdate = false;
      } catch (err) {
        console.log(err)
      }

    },

    async reAffiliateBalise(balise, Id_drone) {
      // a1. si cette balise est déjà affilié à un drone du même groupe que l'utilisateur,
      if (balise.getAffliatedDroneId() == Id_drone) {
        // a2. on ne fais rien si la balise est déjà associée à ce drone sinon
        this.$alert("Cette balise est déjà associée à votre drone!");
        return;
      }

      let oldDrone = await Drone.getDrone(balise.getAffliatedDroneId());
      if (oldDrone == null) {
        /* b1. sinon si la balise est déjà affiliée mais à un drone d'un autre groupe, on
           indique seulement à l'utilisateur qu'il ne peux pas affilier la balise */
        this.$alert("Cette balise ne peux pas être affiliée. Veuillez en utiliser une autre.");
        this.baliseId = '';
        return;
      }

      // a3. on lui propose de réaffilier la balise à son drone
      if (await this.$confirm("Cette balise est déjà affiliée à un autre drone. Voulez-vous la réaffilier à votre drone?")) {
        // on réassigne cette balise dans la table Air_drone
        oldDrone.removeBalise(balise);
        await oldDrone.save();
        await this.affiliateBalise(balise, Id_drone);
      }
    },
    
    async affiliateBalise(balise, Id_drone) {
        try {
          let drone = await Drone.getDrone(Id_drone);
          drone.setBalise(balise);
          await drone.save();

          this.$notify({type: 'err',text: "Affiliation effective !"});
        } catch (err) {
          this.$notify({type: 'warning',text: "Une erreur est survenue durant l'affiliation !"});
          return false
        }

        this.showModalAffiliation = false
    },

    async createNewBalise(techno) {
      const newBaliseData = { Name: this.baliseId };

      this.$notify({type: 'warn',text: "Balise inconnue, nous l'enregistrons et l'affilions..."});
      this.showModalAffiliation = false;

      switch (techno) {
        case 'Wi-fi':
          return new Beacon(newBaliseData);
        case 'LLRTM':
          return new LLRTM(newBaliseData);
        case 'Satellite':
          return new Satellite(newBaliseData);
      }
    },

    // Fonction d'affiliation d'un balise à un drone
    async affiliateDrone(techno) {
      let balise;

      try {
        // 1. on vérifie d'abord que la balise d'entrée existe dans notre bdd
        switch (techno) {
          case 'Wi-fi':
            balise = await Beacon.getWIFIBalise(this.baliseId);
            break;
          case 'LLRTM':
            balise = await LLRTM.getLLRTMBalise(this.baliseId);
            break;
          case 'Satellite':
            balise = await Satellite.getSatelliteBalise(this.baliseId);
            break;

          default: // s'il n'y a rien à maj on annule
            this.$notify({type: 'error',text: 'Nom de balise erronée !'});
            return false;
        }
      } catch (err) {
        // 2.
        /* si la requête échoue avec le code 404, cela signifie que la balise n'existe pas
        On la créée donc avec son identité et l'affiliation */
        if (err.response?.status == 404) {
          balise = await this.createNewBalise(techno);
        }
      }

      // a. si la balise existe on veut vérifier si elle est déjà affiliée à un drone
      if(balise.isAffiliated()) {
        await this.reAffiliateBalise(balise, this.modalAffiliationData.Id_drone);
      } else {
        // b. si la balise n'est pas affiliée, on le fait
        await this.affiliateBalise(balise, this.modalAffiliationData.Id_drone);
      }

      // maj de la liste des drones
      this.dronesData = await Drone.getDrones();
    },

    // Supprime un drone choisis dans la liste
    async deleteDrone(dataDrone) {
      // Enfin on peut supprimer le drone de la table air_drone
      try {
        await (await Drone.getDrone(dataDrone.Id_drone)).remove();
        this.$notify({type: 'error',text: 'Drone supprimé !'});       // notification de confirmation
      } catch(error) {
        console.log(error);
      }
  
      // pis on rafraîchit
      this.dronesData = await Drone.getDrones();
    },
  },

  //remplissage de la liste de drones au chargement de la page
  async mounted() {
    this.dronesData = await Drone.getDrones();
  },
}
</script>

<style scoped>
.container {
  max-width: 500px;
}
</style>
