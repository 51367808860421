<!-- composant drone qui réunis les trois techno : llrtm/wifi/satellite-->
<template>
  <div>
    <!-- pour chaque drone on créé une variable locale lastBaliseData contenant les données de la dernière balise détectée -->
    <div v-for="drone in dronesData" :key="drone.Nom_drone"
         :set="lastBaliseData = drone.LastDetectedBalise || null">

      <!-- Un icône de drone est créé pour chaque drone valide
       il nous faut à minima une position gps -->
      <l-marker
          v-if="validateMarker(lastBaliseData)"
          :icon="droneIcon" @click="setInfoDrone(drone)"
          :lat-lng="[lastBaliseData.Position.Latitude, lastBaliseData.Position.Longitude]">

        <!-- Bulle d'infos (lors du survol de l'icône) du drone -->
        <l-tooltip style="text-align: left">
          <div class="row" v-if="drone.hasOwnProperty('Nom_drone')">
            <div class="col text-secondary">Drone :</div>
            <div class="col">{{ drone.Nom_drone }}</div>
          </div>

          <!-- Informations globales et dynamiques de détection -->
          <div class="row">
            <div class="col text-secondary">Dernière détection :</div>
            <div class="col">{{ lastBaliseData.LastDetection.date }}</div>
          </div>
          <div class="row">
            <div class="col text-secondary">Technologie de détection :</div>
            <div class="col text-center">{{ lastBaliseData.Tech }}</div>
          </div>
        </l-tooltip>
      </l-marker>

      <!-- Trajet actuel du drone s'affichant lors du clic sur le drone -->
      <l-polyline v-if="dronePath && showPathDrone" :interactive="true"
                  :lat-lngs="dronePath" :color="'#C53514'" :weight="2" :dashArray="'3, 3'">
        <!-- weight: taille du trait, dashArray: espace entre chaque point-->
      </l-polyline>

      <!-- cage du drone -->
      <l-polygon v-if="cageDrone"
                 :interactive="true" :name="cageDrone.Nom || null"
                 :lat-lngs="cageDrone.Coordinates"
                 :color="'red'"></l-polygon>

      <!-- Tracé des waypoints de la mission du drone -->
      <l-polyline v-if="missionPath" :lat-lngs="missionPath" color="red"></l-polyline>
      <div v-for="(coord, index) in missionPath" v-bind:key="index">
        <l-circle :lat-lng="coord" :radius="50" color="red"/>
      </div>

      <!-- Modal de détails d'un drone -->
      <b-sidebar v-model="showSideBarDrone" width="400px" no-header no-enforce-focus
                 backdrop-variant="transparent" right shadow no-close-on-backdrop
                 sidebar-class="border-left border-dark bg-secondary aircraftModal">
        <div
            :style="'font-size: ' + $store.getters.sidebarProp.fontSize + '; overflow-x: hidden; overflow-y: scroll;'"
            class="px-3 py-2 mt-2"
            v-if="infoSideBarDrone !== null">
          <div
              :style="'font-size: ' + $store.getters.sidebarProp.fontSize + '; overflow-x: hidden; overflow-y: scroll; height: ' + $store.getters.sidebarProp.height"
              class="px-3 py-2 mt-2" v-if="infoSideBarDrone !== null">
            <!-- bouton de fermeture du modal -->
            <b-button class="btn btn-danger closeBtn"
                      @click="showSideBarDrone = false;">
              &times;
            </b-button>
            <!-- nom du drone -->
            <div :style="'font-size: ' + $store.getters.sidebarProp.titleFontsize">
              {{infoSideBarDrone.Nom_drone}}
            </div>

            <hr class="hrAeronef">
            <!-- Date dernière détection -->
            <h6 class="text-secondary">Dernière détection :</h6>
            <div class="text-center">
              {{ infoSideBarDrone.LastDetectedBalise.LastDetection.date }}
            </div>


            <!-- ------------- Données LLRTM ----------------->
            <div v-if="infoSideBarDrone.LLRTM && infoSideBarDrone.LLRTM.LastDetection"
                 :set="baliseLLRTM = infoSideBarDrone.LLRTM">

              <div :style="'font-size: ' + $store.getters.sidebarProp.titleFontsize" class="col-12 titleRow mt-4"
                   v-b-toggle.collapseLLRTM>Données LLRTM
              </div>
              <b-collapse id="collapseLLRTM" visible class="mt-2">
                <!-- Dernière détection llrtm -->
                <b-row>
                  <b-col class="text-secondary" cols="4">Détection:</b-col>
                  <b-col v-if="baliseLLRTM">{{ baliseLLRTM.LastDetection.date }}</b-col>
                </b-row>

                <!-- Id balise -->
                <b-row class="mt-2">
                  <b-col class="text-secondary" cols="4">Identifiant:</b-col>
                  <b-col>{{ baliseLLRTM.name }}</b-col>
                </b-row>

                <!-- Altitude -->
                <b-row class="mt-2">
                  <b-col class="text-secondary" cols="4">Altitude:</b-col>
                  <b-col>{{ baliseLLRTM.Position.Altitude.toFixed(0) }} m</b-col>
                </b-row>

                <!-- Vitesse -->
                <b-row>
                  <b-col class="text-secondary" cols="4">Vitesse:</b-col>
                  <b-col>{{ baliseLLRTM.Position.Speed.toFixed(0) }} m/s</b-col>
                </b-row>
              </b-collapse>
            </div>

            <!-- ------------- Données Wi-fi ----------------->
            <!-- && getMinutesBetweenDates(getArrayObj(infoSideBarDrone, 'Identite').Timestamp*1000) < 5)-->
            <div v-if="infoSideBarDrone.WIFI && infoSideBarDrone.WIFI.LastDetection"
                 :set="baliseWifi = infoSideBarDrone.WIFI">

              <div :style="'font-size: ' + $store.getters.sidebarProp.titleFontsize" class="col titleRow mt-3"
                   v-b-toggle.collapseWifi>Données Wi-Fi
              </div>

              <b-collapse id="collapseWifi" visible class="mt-2">
                <!-- Dernière détection wifi -->
                <b-row>
                  <b-col class="text-secondary" cols="4">Détection:</b-col>
                  <b-col v-if="baliseWifi">{{ baliseWifi.LastDetection.date }}</b-col>
                </b-row>

                <!-- Dernière détection wifi -->
                <b-row class="mt-3">
                  <b-col class="text-secondary" cols="1">ID:</b-col>
                  <b-col cols="8">{{ baliseWifi.name }}</b-col>
                </b-row>

                <!-- Altitude -->
                <b-row class="mt-2">
                  <b-col class="text-secondary" cols="4">Altitude:</b-col>
                  <b-col >{{ baliseWifi.Position.Altitude }} m</b-col>
                </b-row>

                <!-- Vitesse -->
                <b-row class="mt-1">
                  <b-col class="text-secondary" cols="4">Vitesse:</b-col>
                  <b-col>{{ baliseWifi.Position.Speed.toFixed(0) }} m/s</b-col>
                </b-row>
              </b-collapse>
            </div>

            <!-- ------------- Données Satellite ----------------->
            <div v-if="infoSideBarDrone.Satellite && infoSideBarDrone.Satellite.LastDetection"
                 :set="baliseSat=infoSideBarDrone.Satellite">

              <div :style="'font-size: ' + $store.getters.sidebarProp.titleFontsize" class="col mt-3 titleRow"
                   v-b-toggle.collapseSatellite>Données Satellite
              </div>
              <b-collapse id="collapseSatellite" visible class="mt-2">
                <!-- Dernière détection satellite -->
                <b-row>
                  <b-col class="text-secondary" cols="4">Détection:</b-col>
                  <b-col v-if="baliseSat">{{ baliseSat.LastDetection.date }}</b-col>
                </b-row>

                <!-- Id balise -->
                <b-row class="mt-1">
                  <b-col class="text-secondary" cols="4">Identifiant:</b-col>
                  <b-col>{{ baliseSat.name }}</b-col>
                </b-row>

                <!-- Altitude -->
                <b-row class="mt-1">
                  <b-col class="text-secondary" cols="4">Altitude:</b-col>
                  <b-col >{{ baliseSat.Position.Altitude.toFixed(0) }} m</b-col>
                </b-row>

                <!-- Position GPS -->
                <b-row class="mt-1">
                  <b-col class="text-secondary" cols="4">Position:</b-col>
                  <b-col v-if="baliseSat.hasOwnProperty('Position')">
                    [{{ baliseSat.Position.Latitude.toFixed(5) }}][{{ baliseSat.Position.Longitude.toFixed(5) }}]
                  </b-col>
                </b-row>
              </b-collapse>
            </div>

            <!-- données de la mission associée -->
            <div v-if="missionDrone">
              <div :style="'font-size: ' + $store.getters.sidebarProp.titleFontsize" class="col titleRow mt-4" visible
                   v-b-toggle.collapseMission>Données de mission
              </div>
              <b-collapse id="collapseMission" visible class="mt-2">
                <div class="mt-3">
                  <h6 class="text-secondary">Nom de la mission : <span class="text-black">{{
                      missionDrone.Nom_mission
                    }}</span></h6>
                  <div v-if="altitudeData !== null" class="text-center">
                    <b-button @click="showPathAlti = !showPathAlti" variant="primary" class="shadow">Profil Altimétrique
                    </b-button>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </b-sidebar>
    </div>
    <!--  Affichage du profil de la mission liée au drone sélectionné  -->
    <l-control v-if="altitudeData && missionPath" position="bottomleft">
      <DroneAltiChart style="width: 98vw; color: white;" v-show="showPathAlti" 
          :mission-name="missionDrone.Nom_mission" :mission-data="missionPath"
          :altitude-data="missionAltiProfile"/>
    </l-control>
  </div>
</template>

<script>
import AtlasBackend from '../AtlasBackend.mjs';
import Drone from '../Drone.mjs';
import L from "leaflet";
import {
  LMarker,
  LTooltip,
  LPolyline,
  LPolygon,
  LCircle,
  LControl
} from 'vue2-leaflet';
import * as fct from './componentFunctions';
import DroneAltiChart from "./DroneAltiChart";


export default {
  components: {
    DroneAltiChart,
    LMarker,
    LTooltip,
    LPolyline,
    LPolygon,
    LCircle,
    LControl
  },
  name: "Drone",
  data() {
    return {
      // Données des drones
      dronesData: [],
      // Données des cages liées aux drones
      cageDrone: null,
      // Données des missions liées aux drones
      missionDrone: null,
      missionPath: null,
      // Données du trajet effectué par le drone
      dronePath: null,
      showPathDrone: false,
      // Statut affichage du modal d'un drone
      showSideBarDrone: false,
      infoSideBarDrone: null,
      showDronesActive: true,
      // Affichage & données du profil altimétrique
      showPathAlti: null,
      altitudeData: null,
      missionAltiProfile: null,

      // Icône drone actif
      droneIcon: L.icon({
        iconUrl: require("@/assets/Drone-Active.png"),
        iconSize: [32, 34],
        iconAnchor: [20, 10],
        tooltipAnchor: [20, 10],
      }),
      // Icône drone inactif
      droneIconInactive: L.icon({
        iconUrl: require("@/assets/Drone-Inactive.png"),
        iconSize: [32, 34],
        iconAnchor: [20, 10],
        tooltipAnchor: [20, 10],
      }),
    }
  },
  methods: {
    //assignations des fctions communes
    sleep: fct.sleep,
    setCoordFormat: fct.setCoordFormat,

    validateMarker(lastBaliseData) {
      return lastBaliseData && lastBaliseData.LastDetection && lastBaliseData.Position.Latitude && 
          lastBaliseData.Position.Longitude && this.getHoursDifference(lastBaliseData)< 24;
    },

    // calcule la différence en heure de la date d'un drone (techno + récente)
    getHoursDifference(balise) {
      let timeDiff = 0

      if (balise.techno === 'LLRTM') {
        timeDiff = (Math.floor(Date.now() / 1000) - balise.obj.Timestamp / 1000) / 3600
      } else if ((balise.techno === 'Wi-fi')) {
        timeDiff = (Math.floor(Date.now() / 1000) - balise.obj.Timestamp) / 3600
      } else if ((balise.techno === 'satellite')) {
        timeDiff = ((new Date() - new Date(balise.obj.Msg_date)) / 1000) / 3600
      }
      return timeDiff
    },

    async getDroneCages(drone) {
      if (drone.Id_cage !== null) {
        try {
          let cageResponse = await AtlasBackend.getDataFrom('/cage/id-drone/' + drone.Id_drone)[0];
          //s'il ya vraiment une cage affiliée, on l'affilié à la variable nourrissant l'affichage
          if (cageResponse)
            this.cageDrone = cageResponse;
        } catch(e) {
          console.log(e)
        }
      }
    },
    async getDroneMissions(drone) {
      try {
        let missionResponse = await AtlasBackend.getDataFrom('/mission/drone/' + drone.Id_drone)[0];
        //s'il ya vraiment une mission affiliée, on l'affilié à la variable nourrissant l'affichage
        if (missionResponse) {
          this.missionDrone = missionResponse,
          this.missionPath = this.setCoordFormat(this.missionDrone.Mission)
          this.altitudeData = await AtlasBackend.postDataTo('/cage/showpathalti', { chemin: this.missionPath, samples: 100 });
        }
      } catch(e) {
        console.log(e);
      }
    },

    // assignation des données du drone sélectionné à son modal
    async setInfoDrone(objDrone) {
      this.showSideBarDrone = true;
      this.showPathDrone = true;
      this.infoSideBarDrone = objDrone;

      // boucle d'affichage ~~ watcher
      while (this.showSideBarDrone) {
        /* le sleep ne peut être trop important au risque de trop retarder la maj,
        mais il faut aussi ne pas trop refaire cette maj*/
        await this.sleep(800)
      }
    }
  },

  // au chargement du composant on boucle sur le get de données
  async mounted() {
    // tant que le composant est demandé on get, boucle d'affichage ~~ watcher
    while (this.showDronesActive) {
      this.dronesData = await Drone.getDrones();
      //console.log(this.dronesData);
      await this.sleep(900)
    }
  },
  destroyed() {
    //il faut stopper la boucle de get des données lors de la destruction
    //du composant drone en assignant false à la variable d'affichage
    this.showDronesActive = false;
  },
  watch: {
    // Remise à niveau des différentes variables lorsque l'on ferme le modal
    showSideBarDrone(showSideBar) {
      if (!showSideBar) {
        this.showPathDrone = false
        this.dronePath = null
        this.infoSideBarDrone = null;
        this.cageDrone = null
        this.missionDrone = null
        this.missionPath = null
        this.altitudeData = null
        this.showPathAlti = null
      }
    },
    showPathAlti(value) {
      if (value === true) {
        this.missionAltiProfile = this.altitudeData
      } else {
        this.missionAltiProfile = null
      }
    }
  }
}
</script>