export default class Coordinates {
    #Data;

    constructor(coordinatesData) {
        this.#Data = coordinatesData;
    }

    get Coordinates() {
        const coord = this.#Data.replace(/\s/g, ',').split(',').map(Number);
        var outputcoord = [];

        for (var cnt = 0; cnt < coord.length - 1;) {
            var arraytmp = []
            while (arraytmp.length !== 3) {
                arraytmp.push(coord[cnt]);
                cnt++;
            }
            outputcoord.push(arraytmp)
        }

        for (var items in outputcoord) {
            var tmp = null;
            tmp = outputcoord[items][0]
            outputcoord[items][0] = outputcoord[items][1]
            outputcoord[items][1] = tmp
        }

        return outputcoord;
    }
}