<!-- Template d'ajout/supervisions de mission -->
<template>
  <b-overlay :show="!missionsData || !dronesData">
    <div class="container">
      <h1 class="mt-4">Mission</h1>
      Ajouter une mission :
      <b-form @submit="createMission" class="text-start">
        <label class="mt-3">Nom de la mission *</label>
        <b-form-input required v-model="missionName" class="mt-1" placeholder="Nom de la mission"></b-form-input>
        <label class="mt-3">Fichier de mission *</label>
        <b-form-file class="form-control" required type="file" @change="handleFileUpload( $event )" plain></b-form-file>
        <label class="mt-3">Drone *</label>
        <b-form-select class="form-select" v-model="selected" :options="dronesData" text-field="Nom_drone" value-field="Id_drone">
          <template #first>
            <b-form-select-option required :value="null" disabled>-- Affecter un drone à cette mission --
            </b-form-select-option>
          </template>
        </b-form-select>
        <label class="mt-3">Cage</label>
        <b-form-select class="form-select" v-model="selectedCage" :options="cagesData" text-field="Nom" value-field="Id_cage">
          <template #first>
            <b-form-select-option required :value="null" disabled>-- Affecter une cage à cette mission --
            </b-form-select-option>
          </template>
        </b-form-select>
        <br>
        <div class="text-center">
          <b-button type="submit" size="lg" variant="success" class="mt-3 shadow-lg">Enregistrer</b-button>
        </div>
      </b-form>
      <hr>
    </div>

    <!-- Liste des missions existantes -->
    <h3>Liste des missions :</h3>
    <p class="mt-3" v-if="missionsData === null || cagesData === null">
      Vous n'avez aucune mission.
    </p>
    <b-table responsive v-else striped hover :fields="fields" :items="missionsData">
      <template #cell(Nom_Mission)="data">
        {{ data.item.Nom }}
      </template>
      <template #cell(Drone_Id)="data">
        {{ getDronesName(data.item.Drone_Id) }}
      </template>
      <template #cell(Nom_de_cage)="data">
        {{ getCagesName(data.item.Cage_Id) }}
      </template>
      <template #cell(Mission_date_creation)="data">
        {{ data.item.CreationDate.toLocaleDateString() }}
      </template>
      <template #cell(afficher)="data">
        <b-button @click="showMissionModal(data.item.Coordinates)" variant="primary">Afficher</b-button>
      </template>
      <template #cell(modifier)="data">
        <b-button @click="showUpdateModal(missionsData.indexOf(data.item))" variant="warning">Modifier</b-button>
      </template>
      <template #cell(supprimer)="data">
        <b-button @click="deleteMission(data.item)" variant="danger">Supprimer</b-button>
      </template>
    </b-table>
    <b-modal centered id="modal-mission-display" size="xl" hide-footer
             title="Affichage de la mission" @shown="showMapProperly">
      <div v-if="showMissionPath" style="width:100%; height:45em;" id="flightMap">
        <l-map ref="flightMap" :zoom="zoom" :center="center"
               :options="{ zoomControl: false, attributionControl: false, minZoom: 4, zoomSnap: 0}">

          <!--   Calque de la carte   -->
          <l-tile-layer
              v-for="tileProvider in tileProviders"
              :key="tileProvider.name"
              :name="tileProvider.name"
              :visible="tileProvider.visible"
              :url="tileProvider.url"
              :attribution="tileProvider.attribution"
              layer-type="base"/>
          <l-polyline :lat-lngs="showMissionPath" color="red"></l-polyline>
          <div v-for="(coord, index) in showMissionPath" v-bind:key="index">
            <l-circle :lat-lng="coord" :radius="50" color="red"/>
          </div>
        </l-map>
      </div>
    </b-modal>
    <b-modal centered id="modal-mission-update" hide-footer
             title="Modification des données d'une mission">
      <b-overlay :show="updating" rounded="sm">
        <div v-if="updateMissionData">
          <b-form @submit="updateMission(updateMissionData.Id)">
            <label class="mt-3">Nom de la mission *</label>
            <b-form-input v-model="dataToUpdate.Nom_mission"></b-form-input>
            <label class="mt-3">Drone associé à la mission *</label>
            <b-form-select required class="form-select" v-model="dataToUpdate.Drones_id" :options="dronesData" text-field="Nom_drone" value-field="Id_drone">
            </b-form-select>
            <label class="mt-3">Cage associée à la mission *</label>
            <b-form-select required class="form-select" v-model="dataToUpdate.Id_cage" :options="cagesData" text-field="Nom" value-field="Id_cage">
            </b-form-select>
            <div class="text-center mt-3">
              <b-button type="submit" size="lg" variant="success" class="mt-3 shadow-lg">Enregistrer</b-button>
            </div>
          </b-form>
        </div>
      </b-overlay>
    </b-modal>
  </b-overlay>
</template>

<script>
import {LMap, LTileLayer, LPolyline, LCircle} from 'vue2-leaflet';
import * as fct from '../componentFunctions';
import Drone from "../../Drone.mjs";
import Cage from "../../Cage.mjs";
import Mission from "../../Mission.mjs";

export default {
  name: "Mission",
  components: {LMap, LTileLayer, LPolyline, LCircle},
  data() {
    return {
      missionName: null,
      missionFile: null,
      missionsData: null,
      fields: [
        "Nom_Mission",
        "Drone_Id",
        "Mission_date_creation",
        "Nom_de_cage",
        "Afficher",
        "Modifier",
        "Supprimer"
      ],
      // Données de mission pour le modal
      modalMission: null,
      // Modification de mission
      updateMissionData: null,
      dataToUpdate: {
        Nom_mission: null,
        Drones_id: null,
        Id_cage: null
      },
      updating: null,
      // Affichage de la mission sur une carte
      showMissionPath: null,
      // Données de drones
      dronesData: null,
      // Nom des drones
      selected: null,
      // Données des cages
      cagesData: [],
      selectedCage: null,
      //zone de base lors du chargement de la carte
      center: [44.544, 4.757],
      zoom: 18,
      markerLatLng: [51.504, -0.159],
      tileProviders: [
        {
          name: 'Satellite',
          visible: true,
          url: 'http://mt1.google.com/vt/lyrs=s&key=AIzaSyBPE7MbrLt2e7f2wY-qsrxf3Oamjl9I9z4&x={x}&y={y}&z={z}',
          attribution:
              'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        },
      ],
    }
  },
  methods: {
    // fonctions communessetCoord
    sleep: fct.sleep,
    async showMissionModal(missionCoordinates) {
      this.showMissionPath = missionCoordinates;
      let center = this.showMissionPath;
      center[0].pop()
      this.center = center[0]
      this.$bvModal.show('modal-mission-display')
    },
    handleFileUpload(event) {
      this.missionFile = event.target.files[0];
    },
    // Permet d'afficher le nom des drones sur le tableau
    getDronesName(id) {
      return this.dronesData?.find(drone => drone.Id_drone == id)?.Nom_drone;
    },
    getCagesName(id) {
      return this.cagesData?.find(cage => cage.Id_cage == id)?.Nom;
    },
    // Ajoute une mission
    async createMission() {
      event.preventDefault();

      let newMission = new Mission({
        file: this.missionFile,
        name: this.missionName,
        droneId: this.selected,
        cageId: this.selectedCage,
      });
  
      await newMission.save();

      this.missionName = null;
      this.selected = null
      this.selectedCage = null;

      this.missionsData = await Mission.getMissions();
      this.dronesData = await Drone.getDrones();
    },
    // Supprime une mission
    async deleteMission(mission) {
      try {
        await mission.remove();
      } catch (error) {
        console.log(error);
      }

      this.missionsData = await Mission.getMissions();
      this.dronesData = await Drone.getDrones();
    },
    async updateMission(id) {
      event.preventDefault();

      let updatedMission = await Mission.getMission(id);
      updatedMission.Nom = this.dataToUpdate.Nom_mission;
      updatedMission.Drone_Id = this.dataToUpdate.Drones_id;
      updatedMission.Cage_Id = this.dataToUpdate.Id_cage
      
      await updatedMission.save();

      this.missionsData = await Mission.getMissions();
      this.$bvModal.hide('modal-mission-update');
    },
    // Modifier les données d'une mission
    showUpdateModal(index) {
      this.modalMission = this.missionsData[index];
      this.updateMissionData = this.modalMission;
      this.dataToUpdate.Nom_mission = this.updateMissionData.Nom;
      this.dataToUpdate.Drones_id = this.updateMissionData.Drone_Id;
      this.dataToUpdate.Id_cage = this.updateMissionData.Cage_Id;

      this.$bvModal.show('modal-mission-update');
    },
    // repositionne la carte après le chargement du container
    async showMapProperly() {
      await this.sleep(0.1)
      this.$refs.flightMap.mapObject.invalidateSize();
    },
  },
  async mounted() {
    this.missionsData = await Mission.getMissions();
    this.cagesData = await Cage.getCages();
    this.dronesData = await Drone.getDrones();
  }
}
</script>

<style scoped>
.container {
  max-width: 500px;
}
</style>