/*
    Composant map utilisé pour initaliser et manipuler le composant leaflet map
*/
import L from "leaflet";
import turf from '@turf/turf';

export default class Map {
    #leafletMap;
    #linesArray;
    #takeoffIcon;

    constructor() {
        // création de la carte leaflet
        this.#leafletMap = new L.map('map');
        this.#leafletMap.setView([43.5438119047266, 5.758923156339097],15);
        // point de décollage
        //this.#takeoffIcon = L.icon({ iconUrl: import("@/assets/cible.png"), iconSize: [8, 8] });

        // Couche de satellite google
        let tiles = L.tileLayer('https://mt1.google.com/vt/lyrs=s&key=AIzaSyBPE7MbrLt2e7f2wY-qsrxf3Oamjl9I9z4&x={x}&y={y}&z={z}')
        tiles.addTo(this.#leafletMap);
        // tableau de trajets
        this.#linesArray = [];
    
    }

    
    // traçe un trajet sur la map
    addLine(path) {

        // couleur du tracé, générée aléatoirement
        const randomColor = "#" + ("00000" + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)).slice(-6);

        // création d'un objet polyline
        const line = new L.polyline(path, {color: randomColor});
        line.addTo(this.#leafletMap);

        // Ajout d'un marker comme point de décollage
        //let takeoffMarker = new L.marker(path[0], {icon: this.#takeoffIcon});
        //takeoffMarker.addTo(this.#leafletMap);

        // Ajout du trajet dans la tableau trajets traçés
        this.#linesArray.push(line);

        // Focus sur ce nouveau trajet
        this.#focus();

        return line;
    }

    // efface un trajet traçé de la map
    removeLine(line) {
        this.#leafletMap.removeLayer(line)
        this.#linesArray = this.#linesArray.filter(l => l != line);
        this.#focus();
    }

    // efface tous les trajets traçés de la map
    removeAllLines() {
        // supprime toutes les couches, soit les trajets, except la couche satellite google
        this.#leafletMap.eachLayer((layer) => {
            if(!layer.hasOwnProperty('_url'))
                this.#leafletMap.removeLayer(layer)
        })
        this.#linesArray = []
    }

    // zoom sur la zone d'encadrement du trajet
    #focus() {
        if (this.#linesArray.length < 1) return;
        // définition des coordonnées pour le centrage de la vue
        let boundsArray = [];
        this.#linesArray.forEach(line => {
            // on récupère les bounds  de chaque vol/polyline
            const boundsNE = line._bounds._northEast;
            const boundsSW = line._bounds._southWest;
            boundsArray.push([boundsNE.lat, boundsNE.lng], [boundsSW.lat, boundsSW.lng])
        });
    
        // on crée un polygone turf avec
        const line = turf.lineString(boundsArray);
        const bbox = turf.bbox(line);
        const bounds = turf.bboxPolygon(bbox).bbox;
        
        // et les bounds de ce polygone sont celles utilisées pour le centrage de la map
        this.#leafletMap.fitBounds([[bounds[0], bounds[1]],[bounds[2],bounds[3]]]);
    }    
}