import AtlasBackend from "./AtlasBackend.mjs";
import Timestamp from "./Timestamp.mjs";
import Position from "./Position.mjs";

export default class Balise {
    #droneId;
    #LastDetection;
    #Pos;

    constructor(baliseData) {
        this.id = baliseData?.id;
        this.name = baliseData?.name || baliseData?.ansi;

        this.#droneId = baliseData.droneId;
        
        if (baliseData.timestamp) {
            this.#LastDetection = new Timestamp(baliseData.timestamp);
        } else if (baliseData.Msg_date) {// TEMP STANDARDISATION
            this.#LastDetection = new Timestamp(baliseData.Msg_date);
        }

        this.#Pos = new Position({
            Longitude: baliseData.longitude,
            Latitude: baliseData.latitude,
            Altitude: baliseData.altitude,
            Speed: baliseData.speed
        });
    }

    get Id() {
        return this.id;
    }

    get LastDetection() {
        return this.#LastDetection;
    }

    get Position() {
        return this.#Pos;
    }

    get Tech() {
        return "Generic";
    }

    async save() {}

    async _save(saveLocation, additionalData) {
        const saveData = {
            droneId: this.getAffliatedDroneId(),
            name: this.name,
            ...additionalData
        };

        if (this.id) {
            AtlasBackend.putDataTo(saveLocation + `${this.id}`, saveData);
        } else {
            AtlasBackend.postDataTo(saveLocation, saveData);
        }
    }

    static async _getBalise(route_name, baliseId) {
        return await AtlasBackend.getDataFrom(`/${route_name}/identite/${baliseId}`);
    }

    isAffiliated() {
        return this.#droneId != null;
    }

    Affiliate(drone) {
        if (drone) {
            this.#droneId = drone.Id_drone;
        } else {
            this.#droneId = null;
        }
    }

    getAffliatedDroneId() {
        return this.#droneId;
    }
}
