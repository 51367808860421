// ######### Fonctions communes à plusieurs composants ######

// Dépendances
const wkx = require('wkx');

//longitude de l'objet géométrique PostGIS : position 
function getLatitude(dronePosition){
    const wkbBuffer = Buffer.from(dronePosition, 'hex')
    const geometry = wkx.Geometry.parse(wkbBuffer)
    return geometry.toGeoJSON().coordinates[0]
}

//longitude de l'objet géométrique PostGIS : position 
function getLongitude(dronePosition){
    const wkbBuffer = Buffer.from(dronePosition, 'hex')
    const geometry = wkx.Geometry.parse(wkbBuffer)
    return geometry.toGeoJSON().coordinates[1]
}

// Altitude de l'objet géométrique PostGIS : position 
function getAltitude(dronePosition){
    const wkbBuffer = Buffer.from(dronePosition, 'hex')
    const geometry = wkx.Geometry.parse(wkbBuffer)
    return geometry.toGeoJSON().coordinates[2]
}

// Permet de connaître la durée entre deux dates (en minutes)
function getMinutesBetweenDates(date) {
    let actualDate = new Date(+new Date())
    let droneDate = new Date(parseInt(date))
    let diff = actualDate.getTime() - droneDate.getTime();
    return (diff / 60000);
}

// Formate l'heure et la date de détection à partir d'un timestamp
function getTimestampTime(timestamp){
    //conversion en nombre
    timestamp = parseInt(timestamp)
    // si l'on utilise un timestamp unix, il faut le * par 1000 pour le convertir
    if(timestamp.toString().length < 11) 
        timestamp = timestamp*1000

    // si la valeur fournie n'est pas un entier renvoie un null
    if (!Number.isInteger(timestamp)) return null
    
    return new Date(timestamp).toLocaleDateString('fr-FR') 
        +" "+ new Date(timestamp).getHours()
        +':'+('0' + new Date(timestamp).getMinutes().toString()).slice(-2)
        +':'+('0' + new Date(timestamp).getSeconds().toString()).slice(-2)
}

// formatte une date préconstruite en un format + user friendly
function formatDateHour(date){
    // si la valeur fournie n'est pas une date on retourne null
    if (date instanceof Date == false) return null

    return (("00" + date.getDate()).slice(-2) + "/" +
            ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
            date.getFullYear() + "  " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2))
}

function getDateFr(date){
    if (date instanceof Date == false) return null
    return new Date(date).toLocaleDateString('fr-FR') 
        +" "+ new Date(date).getHours()
        +':'+('0' + new Date(date).getMinutes().toString()).slice(-2)
        +':'+('0' + new Date(date).getSeconds().toString()).slice(-2)
}

//fonction permettant une pause dans le programme (à utiliser en asynchrone)
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//formatte des coordonnées
function setCoordFormat(coord) {
    coord = coord.replace(/\s/g, ',')
    coord = coord.split(',').map(Number)
    var outputcoord = []
    for (var cnt = 0; cnt < coord.length - 1;) {
        var arraytmp = []
        while (arraytmp.length !== 3) {
        arraytmp.push(coord[cnt]);
        cnt++;
        }
        outputcoord.push(arraytmp)
    }
    for (var items in outputcoord) {
        var tmp = null;
        tmp = outputcoord[items][0]
        outputcoord[items][0] = outputcoord[items][1]
        outputcoord[items][1] = tmp
    }
    return outputcoord
    }

module.exports = {getLatitude, getLongitude, getAltitude, getMinutesBetweenDates, 
    getTimestampTime, sleep, formatDateHour, getDateFr, setCoordFormat};