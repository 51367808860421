<template>
  <div style="height: 100vh">
    <!--  Affichage du composant carte  -->
    <l-map 
      style="font-family: 'Readex Pro', 'sans-serif'; padding-bottom: 20px;" 
      id="map" :zoom="zoom" 
      :center="center"
      :options="{ zoomControl: false, attributionControl: true, minZoom: 4, zoomDelta:0.4, zoomSnap: 0}" 
      :maxBounds="bounds" :maxBoundsViscosity="1.0">
      <!-- 
        minZoom => max zoom out, 4 pour ne pas sortir de la map 
        zoomDelta => niveau de zoom, nécessite zoomSnap:0
      -->

      <!--   Calque de la carte (Open, Topo etc...)   -->
      <l-tile-layer
          v-for="tileProviders in tileProviders"
          :key="tileProviders.name"
          :name="tileProviders.name"
          :visible="tileProviders.visible"
          :url="tileProviders.url"
          :attribution="tileProviders.attribution"
          :noWrap="true"
          layer-type="base"/>

      <!-- Echelle de la carte (km) -->
      <l-control-scale v-if="showChart === false" position="bottomleft"
        :imperial="false" :metric="true" :maxWidth="400"></l-control-scale>

      <!--  Couche des routes et zones aériennes -->
      <TileAIP v-if="showAIP"/>

      <!-- ligne cantarelle ? -->
      <div v-if="showChart">
        <l-polyline :lat-lngs="itineraire" color="red"></l-polyline>
        <div v-for="coord in itineraire" v-bind:key="itineraire[coord]">
          <l-circle :lat-lng="coord" :radius="50" color="red"/>
        </div>
      </div>
      <!-- barre de recherche de lieu -->
      <!--<v-geosearch :options="geosearchOptions" position="topright"/>-->
      
      <!--  Profil altimétrique  -->
      <l-control position="bottomleft">
        <AltiChart 
                  style="width: 98vw; color: white;" v-show="showChart" :mission-data="itineraire"
                  :altitude-data="altitudeData"/>
      </l-control>
      
      <l-control style="text-align: left" position="topleft">
        <div>
          <!--  Interface de gauche contenant les boutons drones/AIP/Stations  -->
          <b-dropdown id="dropdown-1" text="Menu" class="m-md-2 shadow-lg">

            <!-- bouton des aéronefs -->
            <b-dropdown-item @click="showAeronefsActive = !showAeronefsActive"
                             :variant="showAeronefsActive ? 'danger' : null" class="button mt-2">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="showAeronefsActive"
              > <div class="ms-1">Traffic commercial</div>
              </b-form-checkbox>
            </b-dropdown-item>

            <!-- bouton des stations -->
            <b-dropdown-item @click="showStationsActive = !showStationsActive"
              :variant="showStationsActive ? 'danger' : null" class="button mt-2">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="showStationsActive"
              ><div class="ms-1">Stations</div>
              </b-form-checkbox>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>

            <!-- bouton des drones -->
            <b-dropdown-item @click="showDrones = !showDrones"
                :variant="showDrones ? 'danger' : null" class="button mt-2">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="showDrones"
              ><div class="ms-1">Drones</div>
              </b-form-checkbox>
            </b-dropdown-item>

            <!-- bouton des drones Aprs -->
            <b-dropdown-item @click="showAprsActive = !showAprsActive"
                :variant="showAprsActive ? 'danger' : null" class="button mt-2">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="showAprsActive"
              ><div class="ms-1">Balises LLRTM</div>
              </b-form-checkbox>
            </b-dropdown-item>

            <!-- bouton des drones Beacons -->
            <b-dropdown-item @click="showBeaconsActive = !showBeaconsActive"
                :variant="showBeaconsActive ? 'danger' : null" class="button mt-2">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="showBeaconsActive"
              ><div class="ms-1">Balises Wi-fi</div>
              </b-form-checkbox>
            </b-dropdown-item>

            <!-- bouton des drones Satellite -->
            <b-dropdown-item @click="showSatActive = !showSatActive"
                :variant="showSatActive ? 'danger' : null" class="button mt-2">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="showSatActive"
              ><div class="ms-1">Balises Satellite</div>
              </b-form-checkbox>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>

            <!-- Cages -->
            <b-dropdown-item @click="getCages" :variant="showCages ?
              'danger' : null" class="button mt-2">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="showCages"
              ><div class="ms-1">Cages</div>
              </b-form-checkbox>
            </b-dropdown-item>

            <!-- Altimétrie -->
            <b-dropdown-item @click="showPathAlti" :variant="showChart ?
              'danger' : null" class="button mt-2">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="showChart"
              ><div class="ms-1">Altimétrie</div>
              </b-form-checkbox>
            </b-dropdown-item>

            <!-- AIP -->
            <b-dropdown-item @click="showAIP = !showAIP" :variant="showAIP ?
              'danger' : null" class="button mt-2">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="showAIP"
              ><div class="ms-1">Zones aériennes</div>
              </b-form-checkbox>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>

            <!-- Paramétrage mission -->
            <b-dropdown-item @click="openMissionPage" class="button mt-2">
              Mission
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </l-control>

      <!--  Bouton déconnexion  -->
      <l-control style="text-align: left" position="topright">
        <b-button @click="disconnect" class="btn btn-secondary shadow-lg">
          Déconnexion
        </b-button>
      </l-control>

      <!--  --------- Affichages des composants ------------  -->
      <!--  Cage  -->
      <CageComponent v-if="showCages" v-bind:cagesData="cagesData"/>
      <!--  Antenne  -->
      <StationComponent v-if="showStationsActive" v-bind:stations-data="stations" show/>
      <!--  Wi-fi -->
      <Balise v-if="showBeaconsActive" v-bind:baliseData="wifiBalises"/>
      <!--  LLRTM -->
      <Balise v-if="showAprsActive" v-bind:baliseData="llrtmBalises"/>
      <!--  Satellite -->
      <DroneSat v-if="showSatActive" v-bind:sat-data="[]"/>
      <!--  Drones -->
      <Drone v-if="showDrones"/>
      <!-- Aéronefs -->
      <Balise v-if="showAeronefsActive" v-bind:baliseData="adsbBalises"/>

      <!--  Logo Atechsys  -->
      <l-control position="bottomright">
        <img width="70" height="55" alt="" style="filter: drop-shadow(0 0 0.75rem black);"
             src="../assets/Aile_atechsys.png"/>
      </l-control>

      <div position="topright">
        <!-- selectbox de la carte -->
        <l-control-layers/>
        <!-- composant ajoutant un outil de mesure sur la carte -->
        <l-control-polyline-measure :options="{ showUnitControl: true }" />
        <!-- controlleur zoom +/- -->
        <l-control-zoom position="bottomleft"></l-control-zoom>
      </div>
    </l-map>
  </div>
</template>

<script>
//importation des composants leaflet pour la carte
import {
  LMap,
  LTileLayer,
  LControlLayers,
  LControl,
  LControlScale,
  LPolyline,
  LCircle,
  LControlZoom 
} from 'vue2-leaflet';

//importation des composants utilisés
import StationComponent from "../components/Station";
import TileAIP     from "../components/TileAIP";
import CageComponent        from "../components/CageComponent";
import Balise      from "../components/Balise";
import Drone       from '../components/Drone'
import L from "leaflet";
import AltiChart   from '../components/AltiChart.vue';
import DroneSat    from "../components/DroneSat";
import AtlasBackend from '../AtlasBackend.mjs';
import Cage from "../Cage.mjs";

// Imports MQTT
import MQTT from '../config/MQTT.mjs';
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
//import VGeosearch from 'vue2-leaflet-geosearch';
//import '../../node_modules/leaflet-geosearch/dist/geosearch.css';
import * as fct from '../components/componentFunctions';

export default {
  name: 'Map',
  components: {
    //composants pour la représentation des données
    DroneSat, CageComponent, TileAIP, StationComponent,
    Balise, Drone,
    //composants pour la construction de la carte,
    LMap, LTileLayer, LControlLayers,
    LControl, LControlScale, LPolyline,
    LCircle, AltiChart, LControlPolylineMeasure,LControlZoom 
  },
  data() {
    return {
      // clé de l'api de google (pour l'altimétrie et la vue satellite)
      api: "AIzaSyBPE7MbrLt2e7f2wY-qsrxf3Oamjl9I9z4",

      // geosearcher osm
      geosearchOptions: { 
        provider: new OpenStreetMapProvider({params: {'accept-language':'fr'}}),
        // efface le résulat, une fois cliqué, afin d'éviter 
        // un bug lors de la recherche après une recherche réussie
        autoClose: true,
        notFoundMessage: 'Addresse non trouvée',
      },

      // coordoonnées de la ligne Cantarelle
      itineraire: [
        [43.54387, 5.75619],
        [43.51325788030648, 5.80172859215728],
        [43.48219306133771, 5.852402086450763],
        [43.46420214037183, 5.855265773950455],
        [43.45705929989099, 5.848874644321072],
        [43.45705929989099, 5.848874644321072]
      ],

      // Booléens pour afficher ou non les données
      showCages: false,
      showBeaconsActive: false,
      showSatActive: false,
      showAprsActive: false,
      showAeronefsActive: false,
      showStationsActive: false,
      showAIP: false,
      showDrones: false,
      showMission: false,
      showChart: false,
      
      // tableaux des données MQTT de chaque composant
      clientBroker: null,
      wifiBalises:  [],
      llrtmBalises: [],
      adsbBalises:  [],
      stations:     [],

      cagesData: null,
      satData: null,
      altitudeData: null,
      
      // token utilisateur
      tokenUser: this.$store.state.token,

      //zone de base lors du chargement de la carte
      zoom: 7,
      center: [43.544, 5.757],
      bounds: new L.LatLngBounds([-90,-180],[90,180]),

      // couches googlemaps, osm
      tileProviders: [
        {
          name: 'Carte simplifiée',
          visible: false,
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution:
              '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        },
        {
          name: 'Topographie',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
              'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="https://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'Satellite',
          visible: true,
          url: 'https://mt1.google.com/vt/lyrs=s&key=AIzaSyBPE7MbrLt2e7f2wY-qsrxf3Oamjl9I9z4&x={x}&y={y}&z={z}',
          //attribution:
          //    'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        },
        {
          name: 'Satellite et repères',
          visible: false,
          url: 'https://mt1.google.com/vt/lyrs=y&key=AIzaSyBPE7MbrLt2e7f2wY-qsrxf3Oamjl9I9z4&x={x}&y={y}&z={z}',
        },
      ],
    }
  },
  methods: {
    sleep: fct.sleep,

    // affilie les données retournée par le broker
    update() {
      this.wifiBalises = this.clientBroker.wifiBalises;
      this.llrtmBalises = this.clientBroker.llrtmBalises;
      this.adsbBalises = this.clientBroker.adsbBalises;
      this.stations = this.clientBroker.stations;
    },
    //############# Méthodes interrogeant l'API ###############
    async showPathAlti() {
      this.showChart ^= true; // On inverse l'état de showChart
      if (!this.showChart) return; // Early-Return si on dois cacher les charts

      const data = {chemin: this.itineraire, samples: 50};
      this.altitudeData = await AtlasBackend.postDataTo('/cage/showpathalti', data);
    },

    // Données des drones détectés par satellite
    async getSat() {
      this.satData = await AtlasBackend.getDataFrom('/drone/sat/kineis');
    },

    // Données des cages enregistrées 
    async getCages() {
      this.showCages = !this.showCages;
      this.cagesData = await Cage.getCages();
    },

    //Déconnecte l'utilisateur
    disconnect() {
      //on enlève le token utilisateur enregistré
      this.$store.commit('logoutUser');

      //redirection vers la page d'accueil
      this.redirection();
    },

    //redirection de l'utilisateur vers la page d'accueil
    redirection() {
      this.$router.push('/', () => {
        //notification de déconnexion
        this.$notify({
          type: 'warn',
          text: 'Vous avez été déconnecté!',
        });
      });
    },
    //ouvre la page de paramétrage d'une mission
    openMissionPage() {
      this.$router.push("missions")
    },
  },

  //############# Watchers actualisant la carte #############
  watch: {
    showSatActive: async function () {
      if (this.showSatActive) {
        while (this.showSatActive) {
          //appel de la fonction qui requête la bdd
          await this.getSat();
          await this.sleep(5000);
        }  
      } else this.satData = null
    },
  },

  //Au chargement de la page carte
  async mounted () {
    this.clientBroker = new MQTT("atlaslive.fr", this.update);
    
    //middleware alternatif
    //on vérifie que l'utilisateur dispose d'un droit d'accès, 
    if (this.$store.state.token === null) {
      //sinon on le redirige vers la page de login
      this.disconnect();
    }
  },

  destroyed() {
    //lors de la destruction du composant Map,
    //il faut arrêter les watchers nous même
    //en assignant false à chaque variable observée
    this.showAprsActive = false;
    this.showBeaconsActive = false;
    this.showAeronefsActive = false;
    this.showStationsActive = false;
    this.showDronesActive = false;
    this.showSatActive = false;
  }
}
</script>