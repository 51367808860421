import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

//Création d'un store vuex qui permet de définir l'état de l'application
//Mais qui permet de stocker également des informations, nous stockerons le token dedans .
export default new Vuex.Store({
    //variables à manipuler dans le store
    state: {
        token: null,
        id_user: null,
        id_role: null,
        map: {
            zoom: null,
            position: null,

        },
        screenSize: {
            width: null,
            height: null,
            widthBreakpoint: null
        },
        sidebarProp: {
            fontSize: null,
            titleFontsize: null,
            height: null
        }
    },
    //plugin de persistance du store
    plugins: [createPersistedState()],

    mutations: {
        //setter du token
        setToken: (state, newValue) => {
            state.message = newValue
        },
        //Etat: connexion d'un utilisateur
        loginUser(state, payload) {
            state.token = payload.token;
            state.id_user = payload.id;
            state.id_role = payload.role;
        },
        //Etat déconnexion, on supprime tout token stocké
        logoutUser(state) {
            state.token = null
            state.id_user = null
            state.id_role = null
        },
        getToken(state) {
            return state.token
        },
        setScreenSize(state, payload) {
            state.screenSize.height = payload.height
            state.screenSize.width = payload.width
            state.screenSize.widthBreakpoint = payload.widthBreakpoint
            state.sidebarProp.fontSize = payload.sidebarFontsize
            state.sidebarProp.titleFontsize = payload.titleFontsize
            state.sidebarProp.height = payload.sidebarHeight
        }
    },
    //getters des variables stockées
    getters: {
        token: state => state.token,
        id_user: state => state.id_user,
        id_role: state => state.id_role,
        screenSize: state => state.screenSize,
        sidebarProp : state => state.sidebarProp
    }
})
