<template>
    <div>
      <!-- Parcours des données de chaques antennes -->
      <div v-for="(antenneData, index) in baliseData" :key="index">
        <!-- Parcours des balises -->
        <div v-for="drone in antenneData[1]" :key="drone.name"> 
          <l-marker
              v-if="(drone.latitude && drone.longitude && getMinutesBetweenDates(drone.timestamp) < 5 || drone.timestamp == null)"
              :icon="dronesStatus(parseInt(drone.timestamp), drone)"
              @click="setInfoDrone(drone.name, index)"
              :lat-lng="[drone.latitude, drone.longitude]">
  
            <!-- Bulle d'infos (lors du survol de l'icône) du drone -->
            <l-tooltip style="text-align: left">
                Balise {{ drone.tech }}: {{ drone.name }} <br>
                Détection : {{getDroneTime(drone.timestamp)}}<br>
            </l-tooltip>
          </l-marker>
        </div>
      </div>
  
      <!-- Modal de détails de chaque aéronef, placé sur la droite -->
      <b-sidebar v-model="showSideBarDrone"
        width="400px" height="300px" id="sidebar-ae"
        backdrop backdrop-variant="transparent"
        no-header right shadow
        sidebar-class="border-left border-dark bg-secondary aircraftModal">
        <!-- police responsive pour le modal -->
        <div :style="'font-size: ' + $store.getters.sidebarProp.fontSize" class="px-3 py-2 mt-3">
          <div class="container" v-if="infoSideBarDrone !== null">
            <div class="row">
              <div class="col-9">
                <div class="text-secondary" :style="'font-size: ' + $store.getters.sidebarProp.titleFontsize">
                  {{ "Balise d'identification " + infoSideBarDrone.tech }}
                </div>
              </div>
              <div class="col-3"> <!-- bouton de fermeture du modal -->
                <b-button class="btn btn-danger closeBtn"
                  @click="showSideBarDrone = !showSideBarDrone">
                    &times;
                </b-button>
              </div>
            </div>

            <!-- Identifiant de la balise -->
            {{ infoSideBarDrone.name }}
            <!-- type de balise flarm -->
            <b-row v-if="infoSideBarDrone.hasOwnProperty('typeDescription')">
              <b-col>{{ infoSideBarDrone.typeDescription }}</b-col>
            </b-row>
            <hr class="hrAeronef"> 
  
            <!-- Date dernière détection -->
            <b-row v-if="infoSideBarDrone.hasOwnProperty('timestamp')">
              <b-col class="text-secondary" cols="4">Détection:</b-col>
              <b-col cols="8">{{ getDroneTime(infoSideBarDrone.timestamp) }}</b-col>
            </b-row>

            <b-row v-if="infoSideBarDrone.hasOwnProperty('flight')">
              <b-col class="text-secondary" cols="3">Vol:</b-col>
              <b-col>{{ infoSideBarDrone.flight }}</b-col>
            </b-row>
  
            <div class="col-12 titleRow mt-3 mb-2">Données de vol</div>
            <!-- Vitesses -->
            <b-row v-if="infoSideBarDrone.hasOwnProperty('groundSpeed')">
              <b-col class="text-secondary" cols="3">Vitesse:</b-col>
              <b-col>{{ parseFloat(infoSideBarDrone.groundSpeed).toFixed(2) }} m/s </b-col>
            </b-row>
  
            <!-- Altitude -->
            <b-row v-if="infoSideBarDrone.hasOwnProperty('altitude')" class="mt-1">
              <b-col class="text-secondary" cols="3"> Altitude:</b-col>
              <b-col >{{ infoSideBarDrone.altitude }} mètres</b-col>
            </b-row>
  
            <!-- Hauteur sol -->
            <b-row v-if="infoSideBarDrone.hasOwnProperty('height')" class="mt-1">
              <b-col class="text-secondary" cols="3"> Hauteur:</b-col>
              <b-col >{{ infoSideBarDrone.height }} mètres</b-col>
            </b-row>
  
            <div class="col-12 titleRow mt-3 mb-2">Positions</div>
            <!-- Pos GPS actuelle -->
            <b-row class="mt-1">
              <b-col class="text-secondary" cols="3"> Actuelle:</b-col>
              <b-col >
                [{{ parseFloat(infoSideBarDrone.latitude).toFixed(4) }}]
                [{{ parseFloat(infoSideBarDrone.longitude).toFixed(4) }}]
              </b-col>
            </b-row>
  
            <!-- Pos GPS de décollage -->
            <b-row 
              v-if="infoSideBarDrone.hasOwnProperty('latitude0') 
              && infoSideBarDrone.hasOwnProperty('longitude0')" class="mt-1">
              
              <b-col class="text-secondary" cols="3">Décollage:</b-col>
              <b-col>
                [{{ parseFloat(infoSideBarDrone.latitude0).toFixed(4) }}]
                [{{ parseFloat(infoSideBarDrone.longitude0).toFixed(4) }}]
              </b-col>
            </b-row>
          </div>
        </div>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import {LMarker,LTooltip} from 'vue2-leaflet';
  import L from "leaflet";
  import * as fct from './componentFunctions';
  
  export default {
    name: "Balise",
    components: {LMarker,LTooltip},
    props: { baliseData: Array},
    data() {
      return {
        // Icônes status
        droneIcon: L.icon({
          iconUrl: require("@/assets/cible_drone.png"),
          iconSize: [32, 34],
          iconAnchor: [20, 10],
          tooltipAnchor: [20, 10],
        }),
        droneIconInactive: L.icon({
          iconUrl: require("@/assets/cible_inactive.png"),
          iconSize: [32, 34],
          iconAnchor: [20, 10],
          tooltipAnchor: [20, 10],
        }),
        droneIconSelection: L.icon({
          iconUrl: require("@/assets/cible_selection.png"),
          iconSize: [32, 34],
          iconAnchor: [20, 10],
          tooltipAnchor: [20, 10],
        }),
        adsbIcon: L.icon({
          iconUrl: require("@/assets/cible.png"),
          iconSize: [32, 34],
          iconAnchor: [20, 10],
          tooltipAnchor: [20, 10],
        }),
        // variable d'affichage de composant
        showSideBarDrone: false,
        infoSideBarDrone: null,
        selection: null,
      }
    },
    methods: {
      // Assignation des fonctions communes au scope local
      getDroneTime: fct.getTimestampTime,
      getMinutesBetweenDates: fct.getMinutesBetweenDates,
      sleep: fct.sleep,
  
      // Retourne l'état de la balise en fonction de la dernière date de détection
      dronesStatus(date, drone) {
        if(this.selection === drone.name) {
            return this.droneIconSelection
        }
        // actif - 3 minutes | inactif > 3 minutes | disparaît > 5 min
        if (!date) {
          return this.droneIcon;
        }
        
        const minutes = this.getMinutesBetweenDates(date)
        if (minutes < 3) {
            if(drone.tech == 'ADSB') return this.adsbIcon
            else return this.droneIcon;
        } else {
            return this.droneIconInactive;
        }
      },
      // Associe les données de la balise sélectionnée à la variable du modal
      async setInfoDrone(droneName, index) {
        this.selection = droneName
        this.showSideBarDrone = !this.showSideBarDrone;
  
        while (this.showSideBarDrone === true) {
          // on récupère les données actualisées de l'antenne contenant la balise
          let antenneData = this.baliseData[index][1]
  
          // parcours des balises enregistrées
          for (let drone in antenneData) {
            //console.log(this.baliseData)
            if (antenneData[drone].name === droneName) {
              this.infoSideBarDrone = antenneData[drone]
            }
          }
          await this.sleep(350)
        }
      },
    },
    watch: {
      // fonction de surlignagne de la balise sélectionnée
      showSideBarDrone(val) {
        if (val === false) this.selection = null
      },
    }
  }
  </script>