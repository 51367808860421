<template>
  <div>
    <!-- Parcours des balises satellite-->
    <div v-for="drone in satData" :key="drone.name">
      <l-marker v-if="drone.latitude && drone.longitude && getHoursDiff(drone.msgDate) < 24" role="button"
                :icon="droneIcon"
                @click="setInfoDrone(drone.name);"
                :lat-lng="[drone.latitude, drone.longitude]">

        <!-- Bulle d'infos (lors du survol de l'icône) du drone -->
        <l-tooltip style="text-align: left">
          Drone : <br>{{ drone.name }}
          <hr>
          Dernière détection : {{new Date(drone.msgDate).toLocaleDateString()
        +" "+ new Date(drone.msgDate).getHours()
        +':'+('0' + new Date(drone.msgDate).getMinutes().toString()).slice(-2)
        +':'+('0' + new Date(drone.msgDate).getSeconds().toString()).slice(-2)}}
        <br>

        <div v-if="drone.hasOwnProperty('altitude')">
          Altitude : {{ drone.altitude.toFixed(3) }} m<br>
        </div>
          Position : [{{ drone.latitude.toFixed(3) }}, {{ drone.longitude.toFixed(3) }}] <br>
        </l-tooltip>
      </l-marker>
      <!-- Modal de détails de chaque aéronef, placé sur la droite -->
      <b-sidebar v-model="showSideBarDrone"
                 width="400px" height="300px" id="sidebar-ae"
                 backdrop backdrop-variant="transparent"
                 no-header right shadow
                 sidebar-class="border-left border-dark bg-secondary aircraftModal">

        <div class="ftSize18 px-3 py-2 mt-3" >
          <!-- bouton de fermeture du modal -->
          <b-button class="closeBtn"
                    @click="showSideBarDrone = !showSideBarDrone">
            &times;
          </b-button>
          <div class="container" v-if="infoSideBarDrone !== null">
            <div class="row">
              <div class="col-10 mb-3">
                <h3>Drone : {{ infoSideBarDrone.name }}</h3>
              </div>

              <hr class="hrAeronef">
              <!-- Détection -->
              <div v-if="infoSideBarDrone.timestamp !== null" class="col-12 mt-3">
                <div class="text-secondary">Dernière détection :</div>
                <div class="text-center">
                  {{new Date(infoSideBarDrone.msgDate).toLocaleDateString("fr-FR")
                +" "+ new Date(infoSideBarDrone.msgDate).getHours()
                +':'+('0' + new Date(infoSideBarDrone.msgDate).getMinutes().toString()).slice(-2)
                +':'+('0' + new Date(infoSideBarDrone.msgDate).getSeconds().toString()).slice(-2)}}
                </div>
              </div>

              <div class="col-12 titleRow mt-5">
                Vitesses | Altitudes
              </div>
              <!-- Altitudes -->
              <div v-if="infoSideBarDronedrone.altitude !== null" class="mt-3">
                <div class="text-secondary"> Altitude : </div>
                <div class="text-center">{{ infoSideBarDronedrone.altitude.toFixed(3) }} mètres</div>
              </div>
              <div class="col-12 titleRow mt-4">
                Position
              </div>
              <!-- Latitude -->
              <div class="col-md-6">
                <div class="text-secondary">Latitude :</div>
                {{ infoSideBarDronedrone.latitude.toFixed(3) }} °
              </div>
              <!-- Longitude -->
              <div class="col-md-6 mb-2">
                <div class="text-secondary">Longitude :</div>
                {{ infoSideBarDronedrone.longitude.toFixed(3) }} °
              </div>
            </div>
          </div>
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import {
  LMarker,
  LTooltip
} from 'vue2-leaflet';
import L from "leaflet";
import * as fct from './componentFunctions';

export default {
  name: "DroneSat",
  components: {
    LMarker,
    LTooltip,
  },
  data() {
    return {
      // Icônes drone
      droneIcon: L.icon({
        iconUrl: require("@/assets/Satellite.png"),
        iconSize: [40, 35],
        iconAnchor: [20, 10],
        tooltipAnchor: [20, 10],
      }),
      infoSideBarDrone: null,
      showSideBarDrone: false,
    }
  },
  props: {
    satData: Array
  },
  methods: {
    // Assignation des fonctions communes au scope local
    getLongitude: fct.getLongitude,
    getDroneTime: fct.getTimestampTime,
    getMinutesBetweenDates: fct.getMinutesBetweenDates,
    sleep:                  fct.sleep,

    // Retourne l'état du drone en fonctoin de la dernière date de détection
    dronesStatus(date) {
      const minutes = this.getMinutesBetweenDates(date*1000)
      if (minutes < 2) {
        return this.droneIcon
      } else {
        return this.droneIconOrange
      }
    },

    // Associe les données du drone sélectionnée à la variable du modal
    async setInfoDrone(droneName) {
      this.showSideBarDrone = !this.showSideBarDrone;

      // boucle d'affichage ~~ watcher
      while (this.showSideBarDrone === true) {

        for (let drone in this.satData) {
          if (this.satData[drone].name === droneName) {
            this.infoSideBarDrone = this.satData[drone]
          }
        }
        await this.sleep(5000)
      }
    },

    // calcule la difference en heure entre la date actuelle et cellu fournie
    getHoursDiff(date){
      return ((new Date()- new Date(date))/1000)/3600
    }
  }
}
</script>

<style scoped>

</style>