import AtlasBackend from './AtlasBackend.mjs';

import Beacon from "./balises/Beacon.mjs";
import LLRTM from "./balises/LLRTM.mjs";
import Satellite from "./balises/Satellite.mjs";

export default class Drone {
    // récupération des données d'un drone ainsi que celles de ses balises associées
    static async getDrones() {
        let drones = [];

        const querry = AtlasBackend.getQuerryForUser("/drone/", "groupe");
        for (const droneData of await AtlasBackend.getDataFrom(querry)) {
            // création d'un objet drone
            let newDrone = new Drone(droneData);
            
            if (droneData.wifiId) newDrone.setBalise(await Beacon.getWIFIBalise(droneData.wifiId));
            if (droneData.llrtmId) newDrone.setBalise(await LLRTM.getLLRTMBalise(droneData.llrtmId));
            if (droneData.satId) newDrone.setBalise(await Satellite.getSatelliteBalise(droneData.satId));

            drones.push(newDrone);
        }

        return drones;
    }

    static async getDrone(Id_drone) {
        return (await this.getDrones()).find(drone => drone.Id_drone == Id_drone);
    }

    #Id;
    #WIFI_balise = null;
    #LLRTM_balise = null;
    #Satellite_balise = null;

    constructor(droneData) {
        this.Nom_drone = droneData.name;
        this.Id_altango = droneData.altangoId;
        this.#Id = droneData.id;
        this.Id_cage = droneData.cageId;
        this.Id_mission = droneData.missionId;
        this.timestamp_order = null;
    }

    get Id_drone() {
        return this.#Id;
    }

    get Id_balise() {
        if (!this.#WIFI_balise) return null;
        return this.#WIFI_balise.name;
    }

    get Id_tracker() {
        if (!this.#LLRTM_balise) return null;
        return this.#LLRTM_balise.name;
    }

    get Id_sat() {
        if (!this.#Satellite_balise) return null;
        return this.#Satellite_balise.name;
    }

    get WIFI() {
        return this.#WIFI_balise;
    }

    get LLRTM() {
        return this.#LLRTM_balise;
    }

    get Satellite() {
        return this.#Satellite_balise;
    }

    // Récupère les données de la balises ayant été détectée en dernière
    get LastDetectedBalise() { 
        let lastDetection = 0;
        let lastBalise = null;

        let balises = [
            this.#WIFI_balise,
            this.#LLRTM_balise,
            this.#Satellite_balise
        ];

        balises.forEach(balise => {
            if (balise == null) return;
            let baliseTimestamp = balise?.LastDetection?.Seconds || 0;
            if (baliseTimestamp && baliseTimestamp > lastDetection) {
                lastDetection = baliseTimestamp;
                lastBalise = balise;
            }
        });

        return lastBalise;
    }

    setBalise(balise) {
        if (balise instanceof Beacon) this.#WIFI_balise = balise;
        else if (balise instanceof LLRTM) this.#LLRTM_balise = balise;
        else if (balise instanceof Satellite) this.#Satellite_balise = balise;

        balise.Affiliate(this);
    }

    removeBalise(balise) {
        if (balise instanceof Beacon) this.#WIFI_balise = null;
        else if (balise instanceof LLRTM) this.#LLRTM_balise = null;
        else if (balise instanceof Satellite) this.#Satellite_balise = null;

        balise.Affiliate(null);
    }

    async save() {
        console.log("Saving")

        const saveData = {
            name: this.Nom_drone,
            altangoId: this.Id_altango,
            wifiId: this.Id_balise,
            llrtmId: this.Id_tracker,
            satId: this.Id_sat,
            groupeId: AtlasBackend.getIdRole()
        };

        const querry = "/drone/";
        if (this.#Id)  {
            await AtlasBackend.putDataTo(querry + `${this.#Id}`, saveData);
        } else {
            console.log("saving at :" + querry)
            console.log(saveData)
            await AtlasBackend.postDataTo(querry, saveData);
        }

        await this.#WIFI_balise?.save();
        await this.#LLRTM_balise?.save();
        await this.#Satellite_balise?.save();
    }

    async remove() {
        await AtlasBackend.deleteDataAt(`/drone/${this.#Id}`);
    }

    async getCages() {
        if (this.Id_cage !== null) {
            try {
                //s'il ya vraiment une cage affiliée, on l'affilié à la variable nourrissant l'affichage
                return (await AtlasBackend.getDataFrom('/cage/id-drone/' + this.#Id))[0];
            } catch(e) {
                console.log(e)
            }
        }
    }

    // récupère le trajet totale du drone sur sa techno la + récente
    async getPath() { // TODO
        // récupération de l'id de la balise de la techno avec la trame la + récente
        let idBalise = this.getRecentDetection()?.identifiant
    
        // si l'id de balise n'est pas bon, inutile de requêter l'api
        if (!idBalise)
            return this.dronePath = null
    
        // on get les vols associés à l'identifiant de notre balise
        try {
            let response = await AtlasBackend.getDataFrom('/vol/id/' + idBalise)[0];
            // on récupère le vol le + récent qui est le premier résultat dans le tableau de réponse (grâce à un group by)
            // on assigne les coordonnées de ce vol à la variable dronePath qui s'applique sur son drone
            this.dronePath = JSON.parse(response.trajet).coordinates
        } catch(err) {
            console.log(err)
        }
    }
}