import AtlasBackend from "./AtlasBackend.mjs";
import Timestamp from "./Timestamp.mjs";

export default class Vol {

    // retourne les 25 vols d'une page spécifique
    static async getVols(page) {
        let vols = [];

        const query = AtlasBackend.getQuerryForUser(`/vol/page/${page}`, "groupe");
        for (const volData of await AtlasBackend.getDataFrom(query)) {
            let newVol = new Vol(volData);
            vols.push(newVol);
        }
        return vols;
    }

    // retourne le nombre de vol total
    static async getNbOfVols() {
        const query = AtlasBackend.getQuerryForUser("/vol/size", "groupe");
        return await AtlasBackend.getDataFrom(query);
    }

    // retourne les vols contenant la chaîne fournie, dans leur droneif ou antenne id
    static async findVols(data) {
        let vols = [];

        const query = AtlasBackend.getQuerryForUser(`/vol/search`, "groupe");
        for (const volData of await AtlasBackend.getDataSearch(query,data)) {
            let newVol = new Vol(volData);
            vols.push(newVol);
        }
        return vols;
    }

    #Id;
    #DateDecollage;
    #DateAterrissage;
    #Duree;
    #Balise;
    #Antenne;
    #Path;
    #FlightLine; // Polyline ajoutée sur la carte 

    constructor(volData) {

        this.#Id              = volData.id;
        this.#DateDecollage   = new Timestamp(volData.takeoffTimestamp);
        this.#DateAterrissage = volData.landingTimestamp;
        this.#Duree           = new Timestamp(volData.flightTime);
        this.#Balise          = volData.droneId;
        this.#Antenne         = volData.antenna;
        this.#Path            = JSON.parse(volData.path)?.coordinates;
        this.Nom              = volData.name;
        this.#FlightLine      = null;
    }

    get Id() {
        return this.#Id;
    }

    get Decollage() {
        return this.#DateDecollage.date;
    }

    get Aterrissage() {
        return this.#DateAterrissage;
    }

    get Duree() {
        return this.#Duree.time;
    }

    get Balise() {
        return this.#Balise;
    }

    get Antenne() {
        return this.#Antenne;
    }

    get FlightLine() {
        return this.#FlightLine;
    }

    // méthode de construction et d'affichage du vol cliqué
    draw(map) {
        //1. si elle n'existe pas alors on l'ajoute à notre carte
        if (this.#FlightLine == null) {
            this.#FlightLine = map.addLine(this.#Path);

        //2. si elle existe, on l'efface et le marker pt de décollage
        } else {
            // de la carte
            map.removeLine(this.#FlightLine);
            this.#FlightLine = null;
        }
    }
}