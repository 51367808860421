<template>
  <div>
    <!-- Page de connexion -->
    <b-overlay :fixed="true" :show="show" no-wrap></b-overlay>
    
    <div class="container">
      <div class="titleLogin mb-5 animate__animated animate__fadeInUp">
        <img class="titleLogo" src="../assets/Aile_atechsys.png"/>TLAS
      </div>
      <div class="animate__animated animate__fadeIn animate__delay-1s">
      <br>
        <!--  Formulaire de connexion   -->
        <b-form class="mt-5" @submit="authenticate">
          <!-- affichage des erreurs d'identifiants -->
          <b class="policeRed" v-if="error">{{ error }}</b>

          <!-- champs des identifiants -->
          <b-form-input 
                      class="mt-3 input" v-model="email" 
                      type="email" placeholder="Email" 
                      maxlength="40" required/>

          <b-form-input 
                      class="mt-3 input" v-model="password" 
                      type="password" placeholder="Mot de passe"
                      maxlength="20" required/>
          <br>
          <b-button type="submit" variant="success">Se connecter</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import AtlasBackend from "../AtlasBackend.mjs";


export default {
  name: "Login",
  data() {
    return {
      error: null,
      email: null,
      password: null,
      show: false,
    }
  },
  methods: {
    // Méthode de connexion à l'api
    async authenticate() {
      event.preventDefault();

      let response;
      try {
        //Requête post de connexion utilisateur à l'api
        const data = { email: this.email, password: this.password };
        response = await AtlasBackend.postDataTo(`/user/login`, data);
        
        if(response == -1){
          //retour de la requête à -1 donc erreur
          this.error = 'Identifiants erronés'
          this.show = false;
          return;
        }

      } catch (err) {
        //erreur lors de la requête (mauvais identifiants)
        //on affiche l'erreur indiquant quel champ est incorrect
        this.error = err.response.data.message;
        this.show = false;
        return;
      }
      this.show = true;

      //succès de la requête
      let decoded = jwt_decode(response.tokenUser);
      
      // payload du token avec les infos utilisateur
      const payload = {
        token: response.tokenUser,
        id: decoded.id,
        role: decoded.role,
        nom: decoded.nom,
        prenom: decoded.prenom
      };

      //on stocke le token utilisateur dans un store vuex
      this.$store.commit('loginUser', payload);
      // on redirige l'utilisateur sur la page map
      this.$router.push('/map', () => {
        //notification de connexion
        this.$notify({
          type: 'success',
          text: 'Vous êtes connecté!',
        });
      });
    }
  },

  mounted() {
    //redirection directe si l'utilisateur s'est déjà authentifié
    if (this.$store.state.token !== null)
      this.$router.push('/map')
    // rajoute une image d'arrière plan
    document.body.style.backgroundImage = `url(${require('../assets/T600.jpg')})`;
    document.body.style.backgroundSize= `2100px 1900px`
  },

  destroyed() {
    // reset de l'image d'arrière-plan pour les autres composants
    document.body.style.backgroundImage = ``;
  }
}
</script>
<style scoped>
.container {
    max-width: 450px;
    margin-top: 10%; 
}

.h1 {
    font-size: 45px;
}
  
@media (max-width: 1200px) {
.container {
    max-width: 530px;
    margin-top: 13%;
}

.h1 {
    font-size: 45px;
}
}

@media (max-width: 992px) {

.container {
    max-width: 530px;
    margin-top: 12%;
}

.h1 {
    font-size: 40px;
}
}

@media (max-width: 768px) {
.container {
    max-width: 580px;
    margin-top: 20%;
}

.h1 {
    font-size: 35px;
}
}

@media (max-width: 576px) {
.container {
    max-width: 500px;
    margin-top: 33%;
}

.h1 {
    font-size: 35px;
}
}
</style>