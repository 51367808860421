import AtlasBackend from "./AtlasBackend.mjs";
import Coordinates from "./Coordinates.mjs";

export default class Mission {
    static async getMissions() {
        let missions = [];

        const querry = AtlasBackend.getQuerryForUser("/mission/", "user");
        for (const missionData of await AtlasBackend.getDataFrom(querry)) {
            let newMission = new Mission(missionData);

            missions.push(newMission);
        }

        return missions;
    }

    static async getMission(Id) {
        return (await this.getMissions()).find(mission => mission.Id ==  Id);
    }

    #Id;
    #CreationDate;
    #Data;
    #file;

    constructor(missionData) {
        this.#Id = missionData.id;
        this.#Data = new Coordinates(missionData.data);
        this.#CreationDate = new Date(missionData.creationDate);
        this.#file = missionData.file;

        this.Nom = missionData.name;
        
        this.Drone_Id = missionData.droneId;
        this.Cage_Id = missionData.cageId;
    }

    get Id() {
        return this.#Id;
    }

    get CreationDate() {
        return this.#CreationDate;
    }

    get Coordinates() {
        return this.#Data.Coordinates;
    }

    async save() {
        const querry = "/mission/";
        if (this.#Id) {
            const saveData = {
                name: this.Nom,
                droneId: this.Drone_Id,
                cageId: this.Cage_Id
            };

            await AtlasBackend.putDataTo(querry + `${this.#Id}`, saveData);
        } else {
            const saveData = new FormData();
            saveData.append('file', this.#file);
            saveData.append('name', this.Nom);
            saveData.append('droneId', this.Drone_Id);
            saveData.append('cageId', this.Cage_Id);
            saveData.append('creationDate', this.#formatDate(new Date()));
    
            await AtlasBackend.postDataTo(querry, saveData);
        }
    }

    async remove() {
        await AtlasBackend.deleteDataAt(`/mission/${this.#Id}`);
    }

    // Formate la date pour insertion en base de données
    #formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
}