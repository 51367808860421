<template>
  <div>
    <div class="container">
      <h1 class="mt-4">Cages</h1>
      Ajouter une cage :
      <b-form @submit="createCage" class="text-start">
        <label class="mt-3">Fichier de cage</label>
        <br>
        <b-form-file class="form-control" required type="file" @change="handleFileUpload( $event )" plain></b-form-file>
        <br>
        <label class="mt-3">Nom de la cage</label>
        <br>
        <b-form-input required v-model="cageName"></b-form-input>
        <label class="mt-3">Drone *</label>
        <b-form-select required class="form-select" v-model="droneSelected" :options="dronesData" text-field="Nom_drone" value-field="Id_drone">
          <template #first>
            <b-form-select-option required :value="null" disabled>-- Affecter un drone à cette cage --
            </b-form-select-option>
          </template>
        </b-form-select>
        <div class="text-center">
          <b-button type="submit" size="lg" variant="success" class="mt-3 shadow-lg">Enregistrer</b-button>
        </div>
      </b-form>
      <hr>
    </div>
    <div>
      <h3>Liste des cages :</h3>
      <p v-if="cagesData === null">
        Vous ne possedez aucune cage.
      </p>
      <b-table responsive v-else striped hover :fields="fields" :items="cagesData">
        <template #cell(nom_drone)="data">
          {{ getDronesName(data.item.Id_drone) }}
        </template>
        <template #cell(afficher)="data">
          <b-button @click="openFlightMap(data.item)" variant="primary">Carte</b-button>
        </template>
        <template #cell(modifier)="data">
          <b-button @click="updateCageModal(data.item)" variant="primary">Modifier</b-button>
        </template>
        <template #cell(supprimer)="data">
          <b-button @click="deleteCage(data.item.Id_cage)" variant="danger">Supprimer</b-button>
        </template>
      </b-table>
      <b-modal id="modal-flight-map" centered size="xl" @shown="showMapProperly">
        <div v-if="showCage" style="width:100%; height:45em;" id="flightMap">
          <l-map ref="flightMap" :zoom="zoom" :center="center"
                 :options="{ zoomControl: false, attributionControl: false, minZoom: 4, zoomSnap: 0}">

            <!--   Calque de la carte   -->
            <l-tile-layer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
                layer-type="base"/>

            <l-polygon
                :interactive="true"
                :lat-lngs="showCage.Coordinates"
                :color="'red'"></l-polygon>
          </l-map>
        </div>
      </b-modal>
      <b-modal id="modal-mission-update" centered hide-footer title="Modification des données d'une cage">
        <b-overlay :show="updating" rounded="sm">
          <div v-if="updateCageData">
            <b-form @submit="updateCage(updateCageData.Id_cage)">
              <label class="mt-3">Nom de la cage *</label>
              <b-form-input required v-model="dataToUpdate.Nom" :placeholder="dataToUpdate.Nom"></b-form-input>
              <label class="mt-3">Drone associé à la cage *</label>
              <b-form-select required class="form-select" v-model="dataToUpdate.Id_drone" :options="dronesData" text-field="Nom_drone" value-field="Id_drone">
              </b-form-select>
              <div class="text-center mt-3">
                <b-button type="submit" size="lg" variant="success" class="mt-3 shadow-lg">Enregistrer</b-button>
              </div>
            </b-form>
          </div>
        </b-overlay>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {LMap, LTileLayer, LPolygon} from 'vue2-leaflet';
import * as fct from '../componentFunctions';

import Drone from '../../Drone.mjs';
import Cage from '../../Cage.mjs';

export default {
  name: "Cages",
  components: {LMap, LTileLayer, LPolygon},
  data() {
    return {
      cageFile: null,
      cageFileContent: null,
      cageName: null,
      cagesData: null,
      droneSelected: null,
      fields: [
        "Nom",
        "Nom_Drone",
        "Afficher",
        "Modifier",
        "Supprimer",
      ],
      cageDeleted: false,
      // Modifications des données d'une cage
      updateCageData: null,
      dataToUpdate: {
        Nom: null,
        Id_drone: null
      },
      updating: null,
      // Données des drones
      dronesData: null,
      options: null,
      // Affichage du modal : Cage sur la carte
      showCage: false,
      //zone de base lors du chargement de la carte
      center: [44.544, 4.757],
      zoom: 18,
      markerLatLng: [51.504, -0.159],
      tileProviders: [
        {
          name: 'Satellite',
          visible: true,
          url: 'http://mt1.google.com/vt/lyrs=s&key=AIzaSyBPE7MbrLt2e7f2wY-qsrxf3Oamjl9I9z4&x={x}&y={y}&z={z}',
          attribution:
              'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        },
      ],
    }
  },
  methods: {
    // fonctions communes
    sleep: fct.sleep,
    // Données des drones
    async openFlightMap(selectedCage) {
      this.showCage = selectedCage
      this.$bvModal.show('modal-flight-map')
      let cagesCoordinates;

      cagesCoordinates = selectedCage.Coordinates;
      cagesCoordinates[0].pop();

      this.center = cagesCoordinates[0]
    },

    async updateCageModal(cageData) {
      this.updateCageData = cageData;
      this.dataToUpdate.Nom = this.updateCageData.Nom;
      this.dataToUpdate.Id_drone = this.updateCageData.Id_drone;
      this.$bvModal.show('modal-mission-update');
    },
    
    // Ajoute une cage
    async createCage() {
      event.preventDefault();

      let newCage = new Cage({
        Nom: this.cageName,
        Id_drone: this.droneSelected,
        cageFile: this.cageFile
      });

      await newCage.save();
      this.droneId = null;

      this.cagesData = await Cage.getCages();
    },
    // Supprime une cage
    async deleteCage(id) {
      try {
        await (await Cage.getCage(id)).remove();
        this.cageDeleted = true;
      } catch (error) {
        console.log(error);
      }

      this.cagesData = await Cage.getCages();
    },
    // Modifie une cage
    async updateCage(id) {
      event.preventDefault();
      this.updating = true;

      let updatedCage = await Cage.getCage(id);
      updatedCage.Nom = this.dataToUpdate.Nom;
      updatedCage.Id_drone = this.dataToUpdate.Id_drone;

      try {
        await updatedCage.save();

        this.cagesData = await Cage.getCages();
        this.updateCageData, this.updating = null;
        this.$bvModal.hide('modal-mission-update');
      } catch (error) {
        console.log(error);
      }
    },

    // Traitement du fichier de cage avant envoi
    handleFileUpload(event) {
      this.cageFile = event.target.files[0];
    },
    // repositionne la carte après le chargement du container
    async showMapProperly() {
      await this.sleep(0.1)
      this.$refs.flightMap.mapObject.invalidateSize();
    },
    // Permet d'afficher le nom des drone sur le tableau
    getDronesName(id) {
      return this.dronesData?.find(drone => drone.Id_drone == id).Nom_drone;
    },
  },
  async mounted() {
    this.cagesData = await Cage.getCages();
    this.dronesData = await Drone.getDrones();
  }
}
</script>

<style scoped>
.container {
  max-width: 500px;
}
</style>