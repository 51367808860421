import Balise from "../Balise.mjs"

export default class Beacon extends Balise {
    static async getWIFIBalise(baliseId) {
        return new Beacon(await this._getBalise("wifi", baliseId));
    }

    constructor(baliseData) {
        super(baliseData);
    }

    get Tech() {
        return "Wifi";
    }

    async save() {
        await this._save("/wifi/", {});
    }
}