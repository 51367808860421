<template>
  <b-navbar class="shadow-lg" toggleable="lg" type="dark" variant="dark">
    <div class="container">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :active="$route.path === '/missions'" to="/missions" href="#">Missions
          </b-nav-item>
          <b-nav-item :active="$route.path === '/drones'" to="/drones" href="#">Drones
          </b-nav-item>
          <b-nav-item :active="$route.path === '/vols'" to="/vols" href="#">Vols
          </b-nav-item>
          <b-nav-item :active="$route.path === '/cages'" to="/cages" href="#">Cages
          </b-nav-item>
          <b-nav-item v-if="$store.getters.id_user==28" :active="$route.path === '/antenne'" to="/antenne" href="#">Antennes
          </b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ms-auto">
          <b-button to="/map" variant="success">Afficher la carte</b-button>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
  
</template>

<script>
export default {
  name: "Navbar",
}
</script>