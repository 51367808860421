<template>
  <div>
    <div class="center">
        <div class="titleLogin"><img src="../assets/Aile_atechsys.png"/>TLAS</div>
        <hr>
        <h1>La page demandée n'existe pas !</h1>
        <b-button @click="goHome" variant="success">Accueil</b-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Page404",
  data() {
    return {
    }
  },
  methods: {
    goHome() {
     this.$router.push('/')
    }
  },
}
</script>