import Balise from "../Balise.mjs"

export default class Satellite extends Balise {
    static async getSatelliteBalise(baliseId) {
        return new Satellite(await this._getBalise("satellite", baliseId));
    }

    constructor(baliseData) {
        super(baliseData);
    }

    get Tech() {
        return "Satellite";
    }

    async save() {
        await this._save("/satellite/", {});
    }
}