<template>
  <div>
    <!-- Affichage des données de station -->
    <div v-for="station in stationsData" 
        :key="station[1].name" :set="station = station[1]">
    
        <!-- Marqueur -->
        <l-marker  v-if="station.hasOwnProperty('latitude')"
          @click="setInfoStation(station);"
          :icon="stationsStatus(station.timestamp)" 
          :lat-lng="[station.latitude, station.longitude]">

          <l-tooltip style="text-align: center">
            {{ station.name }}<br>
            Dernier ping : {{ getStationTime(station.timestamp)}}
          </l-tooltip>
        </l-marker>
      </div>


    <!-- Modal de détails de chaque station, placé sur la droite -->
    <b-sidebar v-model="showSideBarStation"
      width="400px" height="300px" id="sidebar-ae"
      backdrop backdrop-variant="transparent"
      no-header right shadow
      sidebar-class="border-left border-dark bg-secondary aircraftModal">

      <!-- police responsive pour le modal -->
      <div :style="'font-size: ' + $store.getters.sidebarProp.fontSize" class="px-3 py-2 mt-3">
        <div class="container" v-if="infoSideBarStation">

          <!-- Identifiant de l'antenne -->
          <b-row v-if="infoSideBarStation.hasOwnProperty('timestamp')">
            <b-col class="text-secondary" cols="5">station Atlas:</b-col>
            <b-col cols="5">{{ infoSideBarStation.name }}</b-col>
            <b-col>
            <!-- bouton de fermeture du modal -->
            <b-button class="btn btn-danger closeBtn"
                @click="showSideBarStation = !showSideBarStation">
                  &times;
              </b-button>
            </b-col>
          </b-row>
          <hr class="hrAeronef"> 
          
          <!-- Date dernière détection -->
          <b-row v-if="infoSideBarStation.hasOwnProperty('timestamp')">
            <b-col class="text-secondary" cols="3">Détection:</b-col>
            <b-col cols="8">{{ getStationTime(infoSideBarStation.timestamp) }}</b-col>
          </b-row>

          <!-- Altitude -->
          <b-row v-if="infoSideBarStation.hasOwnProperty('altitude')" class="mt-1">
            <b-col class="text-secondary" cols="3"> Altitude:</b-col>
            <b-col >{{ infoSideBarStation.altitude }} mètres</b-col>
          </b-row>
          
          <!-- Description -->
          <b-row class="mt-1">
            <b-col class="text-secondary" cols="3">Description:</b-col>
            <b-col cols="12"> {{ infoSideBarStation.description }} </b-col>
          </b-row>

          <!-- Pos GPS actuelle -->
          <b-row class="mt-1">
            <b-col class="text-secondary" cols="3">Position:</b-col>
            <b-col >
                    [{{ parseFloat(infoSideBarStation.latitude).toFixed(4) }}]
                    [{{ parseFloat(infoSideBarStation.longitude).toFixed(4) }}]
            </b-col>
          </b-row>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {LMarker,LTooltip} from 'vue2-leaflet';
import L from "leaflet";
import * as fct from './componentFunctions';

export default {
  name: "StationComponent",
  components: {LMarker,LTooltip,},
  props: {stationsData: Array},
  data() {
    return {
      // Icônes station
      stationIconActive: L.icon({
        iconUrl: require("@/assets/Station_active.png"),
        iconSize: [30, 37],
        iconAnchor: [20, 10],
        tooltipAnchor: [20, 10],
      }),
      stationIconInactive: L.icon({
        iconUrl: require("@/assets/Station_inactive.png"),
        iconSize: [30, 37],
        iconAnchor: [20, 10],
        tooltipAnchor: [20, 10],
      }),

      infoSideBarStation: null,
      showSideBarStation: false,
    }
  },
  methods: {
    getMinutesBetweenDates: fct.getMinutesBetweenDates,
    getStationTime: fct.getTimestampTime,
    sleep: fct.sleep,

    // change l'icone des antennes en fonction de la dernière date de détection
    stationsStatus(tmp) {
      const minutes = this.getMinutesBetweenDates(tmp * 1000)
      if(minutes < 5)
        return this.stationIconActive;
      else
        return this.stationIconInactive;
    },
    
    // Actualisation du modal tant qu'il est ouvert
    async setInfoStation(antennaData) {
      this.showSideBarStation = !this.showSideBarStation;
      this.infoSideBarStation = antennaData
    },
  },

}
</script>