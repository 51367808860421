<template>
  <div>
    <!-- Parcours des cages enregistrées -->
    <div v-for="cage in cagesData" :key="cage.Id_cage">
      <l-polygon
        :interactive="true" :name="cage.Nom || null" 
        :lat-lngs="cage.Coordinates"
        :color="'red'"></l-polygon>
    </div>
  </div>
</template>

<script>
import {LPolygon} from 'vue2-leaflet';

export default {
  name: "CageComponent",
  components: {LPolygon},
  props: {
    cagesData: Array
  }
}
</script>