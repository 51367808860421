import mqtt from "mqtt"

export default class MQTT {
    #clientBroker;
    #updateCallback;
    #wifiBalises = [];
    #llrtmBalises = [];
    #adsbBalises = [];
    #stations         = [];

    constructor(address, updateCallback) {
        this.#clientBroker = mqtt.connect(
            // url du broker, hébergée sur le serveur Atlas, port 9000, protocole websockets
            `wss://${address}:9000`, {
                // id client géneré pour le différencier dans le broker
                clientId: "clientAtlaslive" + Math.floor(Math.random() * (0 - 9999999)),
                clean: false,
                // identifiants en tant que lecteur sur broker
                username: "atech3",
                password: "Atech345",
                // délai entre chaque tentative de reconnexions
                reconnectPeriod: 5000
            }
        );

        this.#updateCallback = updateCallback;
        
        // abonnements aux chaînes de publications MQTT, pertinentes
        this.#clientBroker.subscribe("wifi/#");
        this.#clientBroker.subscribe("llrtm/#");
        this.#clientBroker.subscribe("adsb/#");
        this.#clientBroker.subscribe("antenne/#");

        // oneline handlers
        this.#clientBroker.on("close",     () => { console.log("Broker Atlas coupé"); });
        this.#clientBroker.on("reconnect", () => { console.log("Reconnexion au broker Atlas"); });
        this.#clientBroker.on("offline",   () => { console.log("Broker Atlas hors-ligne"); });

        this.#clientBroker.on('message', (topic, payload) => {this.#handleBrokerMessages(topic, payload) });
    }

    get wifiBalises() {
        return Object.entries(this.#wifiBalises);
    }

    get llrtmBalises() {
        return Object.entries(this.#llrtmBalises);
    }

    get adsbBalises() {
        return Object.entries(this.#adsbBalises);
    }

    get stations() {
        return Object.entries(this.#stations);
    }

    #handleBrokerMessages(topic, payload) {

        let arrayData = null
        //parsage des données reçues
        try {
            arrayData = JSON.parse(payload);
        }catch (err) {
            // cas trame json mal formattée, on skip
            return
        }

        // switch selon la chaîne de publication lue cad le type de données
        switch (topic) {
            case 'wifi':
                this.#handleWIFI(arrayData);                
                break;

            case 'llrtm':
                this.#handleLLRTM(arrayData);
                break;

            case 'adsb':
                this.#handleADSB(arrayData);
                break;
            
            case 'antenne':
                this.#stations[arrayData.name] = arrayData;
                break;
        }

        if (this.#updateCallback) {
            this.#updateCallback();
        }
    }

    #handleWIFI(arrayData) {
        // ajout info du type de données
        arrayData['tech'] = "WIFI";
        // timestamp unix -> js
        arrayData.timestamp *= 1000;
        // affilaition données locales au tableaux global
        this.#insert(this.#wifiBalises, arrayData);
    }

    #handleLLRTM(arrayData) {
        arrayData['tech'] = "LLRTM";
        arrayData.timestamp *= 1000;
        this.#insert(this.#llrtmBalises, arrayData);
    }

    #handleADSB(arrayData) {
        arrayData.timestamp *= 1000; // TEMP
        for (let data of arrayData.aircraft) {
            data['timestamp'] = arrayData.timestamp;
            data['tech'] = "ADSB";
            data['altitude'] = parseInt(data.geometricAlt / 3.2808);
        }

        this.#adsbBalises[arrayData.messages] = arrayData.aircraft;
    }

    #insert(target, arrayData) {
        const index = arrayData.antenna;
        // si le tableau de l'antenne n'existe pas, on le crée
        if (!target[index]) {
            target[index] = [arrayData];
            return;
        }
        // on cherche la présence de cette balsie dans le tableau de balises
        for (let i = 0; i < target[index].length; i++) {
            if (target[index][i]?.name == arrayData.name) {
                // on la maj s'il elle existe
                target[index][i] = arrayData;
                return;
            }
        }
        // ajout si la balise n'est pas enregistrée
        target[index].push(arrayData)
        return;
    }
}