<!-- 
  Page de recherche et de visualisations de vols effectués par des drones plus précisément les vols 
  sont les trajets enregistrés dans Air_vol.
  Chaque vol/trajet est un ensemble de position géographiques 
-->
<template>
  <div>
    <h1 class="mt-4">Vols</h1>
    <!-- Carte d'affichage des vols -->
    <div class="mt-5">
      <div class="row">
        <div class="col-md">
        <!-- composant acceuillant la carte leaflet -->
        <div id="map" class="flightMap"></div>
      </div>

        <!-- div de b-table -->
        <div class="col-md border-dark border-5 tableFlight">

          <div class="row mb-3">
            <!-- input de recherche d'un vol -->
            <div class="col-9">
              <b-form-input
                v-model="searchStr" type="search"
                placeholder="Id de la balise/antenne puis pressez Entrée"
                class="w-80" @keyup.enter="searchInFlights">
              </b-form-input>
            </div>
 
            <div class="col-3">
              <!-- bouton de nettoyage filtre et checkboxes -->
              <b-button class="w-30" @click="refresh">Actualiser</b-button>
            </div>
          </div>

          <!-- Rendu conditionnel des vols, on charge: -->
          <b-spinner v-if="loading" class="mt-5"></b-spinner>
          <!-- s'il n'y en a aucun après chargement : -->
          <p v-else-if="flightsData === null && loading == false">
            Aucun vol enregistré
          </p>
          <!-- s'il y en a, on construit un tableau dynamique les colonnes 
            sont remplis avec le champ fields qui prends en paramètre un tableau-->
          <b-table v-else id="tableFlights" striped hover
            :fields="col_fields" :items="filteredFlights" 
            primary-key="Id" label-sort-asc="" label-sort-desc=""  
            label-sort-clear="" :sort-compare="dateSort"
            :per-page="perPage">

            <!-- checkbox d'affichage d'un vol -->
            <template #cell(Nom_vol)="data">
              {{ data.item.Nom }}
              <!-- on fournis les données du vol sélectionné -->
              <!-- NB,click.native permet le clic en dehors de la checkbox, il faut check l'event. @change pas adapté nn plus-->
              <b-form-checkbox @click.native="handleCheckboxClick(data.item, $event, map)" />
            </template>

            <!-- Date de décollage -->
            <template #cell(Timestamp_decollage)="data">
              {{ data.item.Decollage || '' }}
            </template>

            <!-- Durée du vol -->
            <template #cell(Duree_vol)="data">
              {{ data.item.Duree || "1s" }}
            </template>

          </b-table>
          <!-- définition de la pagination de la b-table -->
          <b-pagination v-model="currentPage" :per-page="perPage" 
            @change="loadNewPage" aria-controls="tableFlights" 
            :total-rows="numberOfFlights">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import * as fct from '../componentFunctions';

import Vol from '../../Vol.mjs';
import Map from '../../Map.mjs';


export default {
  name: "Vol",
  components: {},

  data() {
    return {
      //données vols avant eventuel filtrage
      flightsData: null,

      //données vols avec filtrage
      filteredFlights: null,
      searchStr: null,
      flightDataCache: [], 

      numberOfFlights: 0,
      flightCoordinates: null,

      //booléens d'affichage
      showModalMap: false,
      showFlight: false,
      filter: '',

      // variables de pagination
      currentPage: 1,
      perPage: 25,

      // Variable pour la map
      map: null,

      // spinner de chargement des données
      loading: false,

      // tableau des noms de colonnes et des champs dans la bdd, associés.
      col_fields: [
        {key: "Nom_vol",              label: ""},
        {key: "Timestamp_decollage",  sortable: true,  label: "Décollage", thStyle: { width: "20%" }, _showDetails: true},
        {key: "Duree_vol",            label: "Durée"},
        {key: "Balise",               sortable: true,  label: "Balise d'identification", thStyle: { width: "40%" }},
        {key: "Antenne",              label: "Antenne"},
      ],
    }
  },
  methods: {
    // fonctions communes
    sleep: fct.sleep,

    // vérifie que la checbkox a bien été cliquée
    handleCheckboxClick(item, event, map) {
      if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'checkbox')
        item.draw(map)
    },
   
    // Call vers l'api pour récupérer les vols enregistrés
    async getFlightsData() {
      this.loading = true;
      try {
        this.numberOfFlights = await Vol.getNbOfVols();
        await this.loadNewPage(this.currentPage);

      } catch(err) { console.log(err)}

      this.loading = false;
    },

    // fais sensiblement la même chose que getFlightsData 
    // sans la partie gestion du cache, des différentes pages, de loadNewPage()
    async refreshFlightsData(){

      // page à afficher
      let value = 1
      // spinner on
      this.loading = true;

      try {
        // nbr de vols + get des vols
        this.numberOfFlights = await Vol.getNbOfVols();
        this.flightDataCache[value] = await Vol.getVols(value);

        // assignations des données
        this.currentPage = value;
        this.flightsData = this.filteredFlights = this.flightDataCache[value];
        // spinner off
        this.loading = false; 
      } catch (err) {
        // en cas d'erreur sur les requêtes on rafraîchit la page
        this.$router.go()
      }
    },

    // requête la bdd afin de trouver le/les vols contenant la chaîne dans l'id drone ou nom antenne
    async searchInFlights(){
      if(this.searchStr.length > 1) {
        this.loading = true
        this.filteredFlights = await Vol.findVols(this.searchStr)
        // recompte du nombre de vols pour la pagination
        this.numberOfFlights = this.filteredFlights.length || 0
        this.loading = false
      }
    },
    // vide l'input de recherche, rerends la 
    // table et efface les trajets tracés
    async refresh(){
      // efface les trajets tracés
      this.map.removeAllLines()
      // refresh els données de la table
      this.refreshFlightsData()
    },

    //fonction de comparaison de date, applicable dans le tableau
    dateSort(a, b, key) {
      if (key === 'Timestamp_decollage') {
        // Assuming the date field is a `Date` object, subtraction
        // works on the date serial number (epoch value)
        if (a[key] < b[key]) return -1
        else if (a[key] > b[key]) return 1
        else return 0
      } else {
        // Let b-table handle sorting other fields (other than `date` field)
        return false
      }
    },

    // enregistre chaque page déjà visionnée, en local
    async loadNewPage(value) { 
      // TODO: Implement caching of pages into an array of pages and only load 
      // new pages from the db if the value in the cache array is undefined
      if (this.flightDataCache[value] == undefined) {
        this.loading = true;
        try {
          this.flightDataCache[value] = await Vol.getVols(value);
        } catch(err) {
          console.log(err);
        }
      }
      this.currentPage = value;
      this.flightsData = this.flightDataCache[value];
      this.filteredFlights = this.flightsData;
      this.loading = false;
    },
  },

  mounted() {
    // get des données des vols enregistrés
    this.getFlightsData()

    // initalisation de la carte leaflet
    this.map = new Map();
  },
}
</script>
<style scoped>
.container {
  max-width: 500px;
}
</style>